
import { Route, Router, Routes } from 'react-router-dom';
import './app.css';
import * as S from './app.styled';
import { SidebarComponent } from './views/components/sidebar';
import { TopBarComponent } from './views/components/topbar';
import { SchedulePage } from './views/modules/schedule/home';
import { FinancialPage } from './views/modules/financial/home';
import { PatientPage } from './views/modules/patient/home';
import { PendantPage } from './views/modules/pendant/home';
import { UserPage } from './views/modules/user/home';
import { OverviewPage } from './views/modules/overview/home';
import { LocalToken } from './utils/local-token';
import { LoginPage } from './views/modules/auth/login/home';
import { useAppSelector } from './redux/hooks/hooks';
import { useAuthService } from './services/auth.service';
import { useEffect } from 'react';
import { ToastComponent } from './views/components/toast';

export function App() {
    const { user, general } = useAppSelector((state) => state);
    const token = LocalToken.getStoredToken();
    const authService = useAuthService();

    useEffect(() => {
        if (token && !user.authUser?.id) {
            authService.getUserData();
        }
    }, [token, authService, user]);

    return (
        <>
            {!token ?
                <LoginPage /> :
                <S.AppContainer className="App">
                    <SidebarComponent />

                    <S.AppMain>
                        <TopBarComponent />
                        <S.AppContent>
                            <Routes>
                                <Route path='/' element={<OverviewPage />} />
                                <Route path='agenda' element={<SchedulePage />} />
                                <Route path='pacientes' element={<PatientPage />} />
                                <Route path='pendentes' element={<PendantPage />} />
                                <Route path='financeiro' element={<FinancialPage />} />
                                <Route path='usuarios' element={<UserPage />} />
                            </Routes>
                        </S.AppContent>
                    </S.AppMain>
                </S.AppContainer>
            }
            <ToastComponent message={general.toastMessage} />
        </>
    );
}
