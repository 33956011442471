import styled from "styled-components";

export const PatientContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const PatientSearchBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const PatientTableSection = styled.section`

`;