import styled from "styled-components";

interface ButtonContainerProps{
    height?: number,
    width?: number,
    buttonStyle: string
}

export const ButtonContainer = styled.button<ButtonContainerProps>`
    cursor: pointer;
    display: flex;
    gap: 8px;
    padding: 17px 16px;
    align-items: center;
    justify-content: center;
    height: ${props=>props.height ? props.height : 40}px;
    width: ${props=>props.width ? `${props.width}px` : '100%'};
    border: none;
    border-radius: 8px;
    ${props=>props.buttonStyle === 'primary' ?
        `
            background-color: var(--blue-color-100);
            span{
                color: var(--white-color);
            }
            
            &:hover{
                background-color: var(--blue-color-80);
            }

            &:disabled {
                background-color: var(--grey-color-40);
                color: var(--grey-color-60);
            }
        ` : 
        props.buttonStyle === 'secondary' ?
        `
            background-color: transparent;
            border: 1px solid var(--blue-color-100);
            span{
                color: var(--blue-color-100);
            }

            &:hover{
                border: 1px solid var(--blue-color-80);
                span{
                    color: var(--blue-color-80);
                }
            }

            &:disabled {
                border-color: var(--grey-color-40);
                span{
                    color: var(--grey-color-40);
                }
            }
        ` :
        `
            background-color: var(--orange-color-100);
            span{
                color: var(--white-color);
            }

            &:hover{
                background-color: var(--orange-color-80);
            }

            &:disabled {
                background-color: var(--orange-color-40);
                span{
                    color: var(--grey-color-40);
                }
            }
        `
    }
`;