import { useMutation } from "react-query";
import { patientQueries, usePatientService } from "../../../../services/patient.service";
import { PatientSchemaType, patientSchema } from "../../../../models/schemas/patient.schema";
import { PatientInterface } from "../../../../models/patient.interface";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { queryClient } from "../../../../utils/query-client";
import { FormPatientDialogHookType } from "./type";
import { addHours, endOfDay, formatDate, startOfDay } from "date-fns";
import { ptBR } from "date-fns/locale";

export function useFormPatientDialogHook(props : FormPatientDialogHookType){
    const patientService = usePatientService();

    const { mutate, isLoading: isLoadingSubmit } = useMutation(
        async (payload: PatientSchemaType) => 
            props.patient?.id ?
            await patientService.editPatient({payload: payload as PatientInterface, onSuccess}) :
            await patientService.createPatient({payload: payload as PatientInterface, onSuccess})
    );

    const patientForm = useForm<PatientSchemaType>({
        resolver: zodResolver(patientSchema),
        defaultValues: {
            ...props.patient,
            birthDate: props.patient?.birthDate ? formatDate(addHours(props.patient.birthDate, 3), 'yyyy-MM-dd', {locale: ptBR}) : ''
        } as PatientSchemaType
    });

    function onSubmit(data : PatientSchemaType){
        console.log(data.birthDate)
        mutate({
            ...data, 
            cpf : data.cpf || null,
            birthDate :  data.birthDate ? `${data.birthDate}T00:00:00.000Z` : ''
        } as PatientInterface);
    }

    function onSuccess(){
        queryClient.refetchQueries(patientQueries.GET_ALL);
        if(!props.patient?.id){
            props.onClose();
        }
    }

    return{
        patientForm,
        isLoadingSubmit,
        onSubmit,
    }
}