import * as S from './styled';
import { addDays, format, formatDate } from 'date-fns';
import { ButtonComponent } from '../../../components/button';
import { ptBR } from 'date-fns/locale';
import { ArrowComponent } from '../../../components/arrow';
import { CardScheduleComponent } from '../../../components/cardSchedule';
import { FormScheduleDialog } from '../formScheduleDialog';

import add_icon from '../../../../assets/icons/add.svg';
import { useScheduleHook } from './hook';
import { LoadingComponent } from '../../../components/loading';

export const SchedulePage = () => {

    const hook = useScheduleHook();

    const CountComponent = ({ day }: { day: number }) => {
        const count = hook.schedules.scheduleData?.rows[formatDate(new Date(hook.general.selectedDate.getFullYear(), hook.general.selectedDate.getMonth(), day), 'yyyy-MM-dd')]?.length;
        return count ? <span>{count}</span> : null;
    }

    const ListScheduleComponent = ({ day }: { day: number }) => {
        const schedules = hook.schedules.scheduleData?.rows[formatDate(new Date(hook.general.selectedDate.getFullYear(), hook.general.selectedDate.getMonth(), day), 'yyyy-MM-dd')];
        return (
            <S.ScheduleList>
                {schedules?.map(schedule => (
                    <CardScheduleComponent schedule={schedule} today={true}/>
                ))}
            </S.ScheduleList>
        );
    }

    return (
        <>
            <S.ScheduleContainer>
                <span className='b1-bold'>Agenda de {formatDate(hook.general.selectedDate, "MMMM 'de' yyyy", { locale: ptBR })}</span>

                <S.ScheduleSearchBar>
                    <S.ScheduleSearchBarInputs>
                        <input type="month" value={format(hook.general.selectedDate, 'yyyy-MM')} onChange={(e) => hook.general.setSelectedDate(addDays(new Date(e.target.value), 1))} />
                    </S.ScheduleSearchBarInputs>
                    <ButtonComponent buttonStyle='primary' width={190} onClick={hook.dialogs.handleOpenDialog}>
                        <img src={add_icon} alt="Icone de adicionar" />
                        <span className='b3-regular'>Novo Agendamento</span>
                    </ButtonComponent>

                </S.ScheduleSearchBar>

                {hook.schedules.isLoading ?
                    <LoadingComponent containerHeight={300} /> :

                    <S.ScheduleTableSection>
                        {hook.general.daysArray.map(day => (
                            <>
                                <S.ScheduleDay key={day} onClick={() => hook.general.handleSelectDay(day)}>
                                    <span className='b2-regular'>
                                        Dia {day} -
                                        <span className='b2-regular'> {format(new Date(hook.general.selectedDate.getFullYear(), hook.general.selectedDate.getMonth(), day), 'EEEE', { locale: ptBR })}</span>
                                    </span>

                                    <div className='actions'>
                                        <CountComponent day={day} />
                                        <ArrowComponent direction={hook.general.selectedDay === day ? 'up' : 'down'} color='blue' />
                                    </div>
                                </S.ScheduleDay>

                                <div className='content'>
                                    <div className={hook.general.selectedDay === day ? 'show' : 'hidden'}>

                                        <ListScheduleComponent day={day} />
                                        <ButtonComponent className="add-button" buttonStyle='primary' width={190} onClick={() => hook.dialogs.setShowFormDialog(true)}>
                                            <img src={add_icon} alt="Icone de adicionar" />
                                            <span className='b3-regular'>Novo Agendamento</span>
                                        </ButtonComponent>
                                    </div>
                                </div>

                            </>
                        ))}
                    </S.ScheduleTableSection>
                }
            </S.ScheduleContainer>



            <FormScheduleDialog
                selectedDate={hook.general.selectedFormDate}
                onClose={() => hook.dialogs.setShowFormDialog(false)}
                isOpen={hook.dialogs.showFormDialog}
            />

        </>
    )
}
