import { setUser } from "../redux/features/user-slice";
import { LocalToken } from "../utils/local-token";
import { useAppDispatch } from "../redux/store";
import { responseStatus, useHandleRequest } from "../api/api-request.service";
import { LoginSchemaType } from "../views/modules/auth/login/home/type";



export function useAuthService() {
    const dispatch = useAppDispatch();
    const handleRequestHook = useHandleRequest();

    async function login(payload: LoginSchemaType) {
        const { status, data } = await handleRequestHook.handleRequest('/auth/user/authenticate', 'POST', payload);
        if (status === responseStatus.SUCCESS) {
            LocalToken.storeToken(data.token.access);
            window.location.href = '/';
        }
    }

    async function getUserData() {
        const token = LocalToken.getStoredToken()
        const response = await handleRequestHook.handleRequest('/auth/verify', 'POST', { access: token });
        dispatch(setUser(response.data.user));
    }

    return {
        login,
        getUserData
    }
}
