import * as S from './styled';

import { FormComponent } from '../../../components/form';
import { ButtonComponent } from '../../../components/button';
import { FormUserDialogType } from './type';
import { useFormUserDialogHook } from './hook';

export const FormUserDialog = (props: FormUserDialogType) => {

    const hook = useFormUserDialogHook(props);

    return (
        <S.FormUserDialogContainer>
            <FormComponent onSubmit={hook.userForm.handleSubmit(hook.onSubmit)}>
                <S.FormUserDialogContent>
                    <div className='form-group'>
                        <label htmlFor="name" className="c1-regular">Nome completo do usuário: *</label>
                        <input type='text' id='name' {...hook.userForm.register('name')} />
                        <span className='error'>{hook.userForm.formState.errors.name?.message}</span>
                    </div>

                    <div className='form-group'>
                        <label htmlFor="cpf" className="c1-regular">CPF: *</label>
                        <input type='text' id='cpf' {...hook.userForm.register('cpf')} />
                        <span className='error'>{hook.userForm.formState.errors.cpf?.message}</span>
                    </div>

                    <div className='form-group'>
                        <label htmlFor="login" className="c1-regular">Login: *</label>
                        <input type='text' id='login' {...hook.userForm.register('login')} />
                        <span className='error'>{hook.userForm.formState.errors.login?.message}</span>
                    </div>

                    <div className='form-group'>
                        <label htmlFor="password" className="c1-regular">Senha: {props.user ? '' : '*'}</label>
                        <input type='text' id='password' placeholder={props.user ? 'Opcional' : ''} {...hook.userForm.register('password')} />
                        <span className='error'>{hook.userForm.formState.errors.password?.message}</span>
                    </div>

                </S.FormUserDialogContent>

                <ButtonComponent buttonStyle='primary' type='submit' disabled={hook.isLoadingSubmit}>
                    <span className="b3-bold">
                        {hook.isLoadingSubmit ? 'Salvando...' : 'Salvar usuário'}
                    </span>
                </ButtonComponent>

            </FormComponent>
        </S.FormUserDialogContainer>
    );
};