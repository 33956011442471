import { useEffect, useState } from "react";
import { PatientDataType, PatientInterface } from "../../../../models/patient.interface";
import { useMutation, useQuery } from "react-query";
import { patientQueries, usePatientService } from "../../../../services/patient.service";

export function usePatientHook(){
    const patientService = usePatientService();
    const [selectedPatient, setSelectedPatient] = useState<PatientInterface | null>(null);
    const [showFormDialog, setShowFormDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showDetailsDialog, setShowDetailsDialog] = useState(false);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');

    const { mutate : mutateDelete, isLoading: isLoadingDelete } = useMutation(
        async () => await patientService.deletePatient({id: selectedPatient!.id!, onSuccess: onSuccessDelete})
    );

    const {
        data : patientData, 
        isLoading : isLoadingPatientData,
        isRefetching : isRefetchingPatientData,
        refetch : refetchPatientData
    } = useQuery<PatientDataType>(patientQueries.GET_ALL, async ()=> await patientService.getAllPatient({search, page}));

    function handleRefetchData(){
        if(page === 1){
            refetchPatientData();
            return;
        }
        setPage(1);
    }

    function onSuccessDelete(){
        handleCloseDeleteModal();
        refetchPatientData();
    }

    function handleCloseFormModal(){
        setSelectedPatient(null);
        setShowFormDialog(false);
    }

    function handleOpenFormModal(patient : PatientInterface){
        setSelectedPatient(patient);
        setShowFormDialog(true);
    }

    function handleCloseDetailsModal(){
        setSelectedPatient(null);
        setShowDetailsDialog(false);
    }

    function handleOpenDetailsModal(patient : PatientInterface){
        setSelectedPatient(patient);
        setShowDetailsDialog(true);
    }

    function handleOpenDeleteModal(patient : PatientInterface){
        setSelectedPatient(patient);
        setShowDeleteDialog(true);
    }

    function handleCloseDeleteModal(){
        setSelectedPatient(null);
        setShowDeleteDialog(false);
    }

    function handleDeletePatient(){
        mutateDelete();
    }

    useEffect(()=>{
        refetchPatientData();
    }, [page]);


    return {
        dialogs: {
            showFormDialog,
            showDetailsDialog,
            showDeleteDialog,
            handleCloseFormModal,
            handleOpenFormModal,
            handleOpenDeleteModal,
            handleCloseDeleteModal,
            handleCloseDetailsModal,
            handleOpenDetailsModal,
            setShowFormDialog,
            handleDeletePatient
        },
        patient: {
            patientData,
            selectedPatient,
            isLoadingDelete,
            isLoading: isLoadingPatientData || isRefetchingPatientData,
            page,
            setPage,
            handleDeletePatient,
            setSearch,
            handleRefetchData
        }
    }
    
}