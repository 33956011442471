import { useState } from "react";
import { useUserService, userQueries } from "../../../../services/user.service";
import { UserDataType, UserInterface } from "../../../../models/user.interface";
import { useMutation, useQuery } from "react-query";

export function useUserHook(){
    const userService = useUserService();
    const [showFormDialog, setShowFormDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState<UserInterface | null>();
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);

    const { mutate : mutateDelete, isLoading: isLoadingDelete } = useMutation(
        async () => await userService.deleteUser({id: selectedUser!.id!, onSuccess: onSuccessDelete})
    );

    const {
        data : userData, 
        isLoading : isLoadingUserData,
        isRefetching : isRefetchingUserData,
        refetch : refetchUserData
    } = useQuery<UserDataType>(userQueries.GET_ALL, async ()=> await userService.getAllUser({search, page}));

    function handleCloseFormModal(){
        setSelectedUser(null);
        setShowFormDialog(false);
    }

    function handleOpenFormModal(user : UserInterface){
        setSelectedUser(user);
        setShowFormDialog(true);
    }

    function handleOpenDeleteModal(user : UserInterface){
        setSelectedUser(user);
        setShowDeleteDialog(true);
    }

    function handleCloseDeleteModal(){
        setSelectedUser(null);
        setShowDeleteDialog(false);
    }

    function onSuccessDelete(){
        handleCloseDeleteModal();
        refetchUserData();
    }

    function handleDeleteUser(){
        mutateDelete();
    }

    return{
        dialogs: {
            showFormDialog,
            showDeleteDialog,
            setShowDeleteDialog,
            setShowFormDialog,
            handleOpenFormModal,
            handleCloseFormModal,
            handleOpenDeleteModal,
            handleCloseDeleteModal
        },
        user: {
            userData,
            selectedUser,
            isLoading : isLoadingUserData || isRefetchingUserData,
            isLoadingDelete,
            page,
            refetchUserData,
            handleDeleteUser,
            setSearch,
            setPage
        }
    }
}