import * as S from './styled';
import { DialogComponent } from '../dialog';
import { FormComponent } from '../form';
import { FormFinalizeDialogType } from './type';
import { ButtonComponent } from '../button';
import { InputCurrencyComponent } from '../inputCurrency';
import { useFormFinalizeDialogHook } from './hook';
import { SchedulePaymentStatus } from '../../../models/schedule.interface';

export const FormFinalizeDialog = (props: FormFinalizeDialogType) => {
    const hook = useFormFinalizeDialogHook(props);

    console.log(hook.paymentForm.formState.errors)
    return (
        <S.FormFinalizeDialogContainer>
            <DialogComponent
                onClose={props.onClose}
                width={330}
                title='Concluir Atendimento'
                isOpen={props.isOpen}
            >
                <FormComponent onSubmit={hook.paymentForm.handleSubmit(hook.onSubmit)}>
                    <S.FormFinalizeDialogContent>
                        <div className='form-group'>
                            <label htmlFor="name" className="c1-regular">Nome completo do paciente:</label>
                            <input type='text' id='name' name='name' value={props.schedule?.patient?.name} disabled />
                        </div>

                        <div className='form-group'>
                            <label htmlFor="value" className="c1-regular">Valor:</label>
                            <InputCurrencyComponent id='value' value={parseFloat(props.schedule?.price || '0')} disabled register={hook.paymentForm.register} />
                        </div>

                        {props.schedule?.paymentStatus === SchedulePaymentStatus.PENDING &&
                            <>

                                <div className='form-group checkbox'>
                                    <label htmlFor='receive'>
                                        <input type='checkbox' id='receive' onChange={() => { hook.setReceive(!hook.receive) }} />
                                        <span>Receber agora</span>
                                    </label>
                                </div>

                                {hook.receive &&
                                    <>
                                        <div className='row'>
                                            <div className='form-group'>
                                                <label htmlFor="addition" className="c1-regular">Acrescimos:</label>
                                                <InputCurrencyComponent id='addition' value={0} register={hook.paymentForm.register} />
                                                <span className='error c1-regular'>{hook.paymentForm.formState.errors.addition?.message}</span>
                                            </div>

                                            <div className='form-group'>
                                                <label htmlFor="discounts" className="c1-regular">Descontos:</label>
                                                <InputCurrencyComponent id='discounts' value={0} register={hook.paymentForm.register} />
                                                <span className='error c1-regular'>{hook.paymentForm.formState.errors.discounts?.message}</span>
                                            </div>
                                        </div>

                                        <div className='form-group'>
                                            <label htmlFor="total" className="c1-regular">Valor à Receber:</label>
                                            <InputCurrencyComponent id='total' allowNegativeValue={true} value={hook.total} disabled register={hook.paymentForm.register} />
                                            <span className='error c1-regular'>{hook.paymentForm.formState.errors.total?.message}</span>
                                        </div>
                                    </>
                                }
                            </>
                        }

                        <div className='form-group'>
                            <label htmlFor="total" className="c1-regular">Observação:</label>
                            <textarea id='total' {...hook.paymentForm.register('observation')}></textarea>
                            <span className='error c1-regular'>{hook.paymentForm.formState.errors.observation?.message}</span>
                        </div>


                    </S.FormFinalizeDialogContent>

                    <ButtonComponent buttonStyle='primary' type='submit' disabled={hook.isLoadingSubmit }>
                        <span className="b3-bold">
                            {hook.isLoadingSubmit ? 'Salvando...' : 'Concluir'}
                        </span>
                    </ButtonComponent>

                </FormComponent>
            </DialogComponent>
        </S.FormFinalizeDialogContainer>
    );
};