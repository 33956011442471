import styled from "styled-components";

export const FormFinalizeDialogContainer = styled.div`
    .row{
        display: flex;
        gap: 16px;
    }

`;

export const FormFinalizeDialogContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    .checkbox{
        label{
            display: flex !important;
            align-items: center;
            gap:20px;
        }

        input{
            width: 20px !important;
        }
    }
`;