import { z } from 'zod';
import { FinancialTypes } from '../financial.interface';

export const financialSchema = z.object({
    id: z.number().optional(),
    date: z.union([
        z.date(),
        z.string().min(1, {message : 'Campo "Data" é obrigatóro'})
    ]),
    hour: z.union([
        z.date(),
        z.string().min(1, {message : 'Campo "Hora" é obrigatório'}),
    ]),
    value: z.string().refine(value => {
        const numericValue = parseInt(value.replace(/[^\d]/g, ''));
        return numericValue !== 0;
    }, {
        message: 'Campo "Valor" é obrigatório'
    }).transform(value=>(parseInt(value.replace(/[^\d]/g, ''))/100).toString()),
    type: z.enum(['IN', 'OUT'], {message : 'Selecione um Tipo de lançamento'}).transform(value=>value),
    observation: z.string().min(1, {message : 'Campo "Observação" é obrigatório'}),
});


export type FinancialSchemaType = z.infer<typeof financialSchema>;
