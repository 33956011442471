import styled from "styled-components";

export const FinancialContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const FinancialSearchBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const FinancialTableSection = styled.section`
    .value{
        display: flex;
        gap: 12px;
        align-items: center;
        font-weight: bold;
    }

    .up>span{
        color: var(--green-color);
    }

    .down>span{
        color: var(--orange-color-100);
    }
`;

export const FinancialSearchBarInputs = styled.div`
    display: flex;
    gap: 12px;

    input[type="date"]{
        padding: 0 12px;
        border: none;
        border-radius: 8px;
        background-color: var(--background-color);
        outline: none;
    }
`;