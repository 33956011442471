import { useQuery } from "react-query";
import { DetailsPatientDialogHookType } from "./type";
import { ScheduleDataType, ScheduleInterface } from "../../../../models/schedule.interface";
import { scheduleQueries, useScheduleService } from "../../../../services/schedule.service";
import { useState } from "react";

export function useDetailsPatientDialogHook(props : DetailsPatientDialogHookType){
    const scheduleService = useScheduleService();
    const [showDialog, setShowDialog] = useState(false);
    const [scheduleReceiveDialog, setScheduleReceiveDialog] = useState<ScheduleInterface | null>(null);
    const {
        data: scheduleData,
        isLoading: isLoadingScheduleData,
        isRefetching: isRefetchingScheduleData,
        refetch: refetchScheduleData
    } = useQuery<ScheduleDataType>(scheduleQueries.GET_BY_PATIENT_ID, async () => await scheduleService.getSchedulePatientId({ 
        patientId : props.patient.id!,
        scheduleStatus: 'SCHEDULED'
    }));

    const {
        data: historyScheduleData,
        isLoading: isLoadingHistoryScheduleData,
        isRefetching: isRefetchingHistoryScheduleData,
        refetch: refetchHistoryScheduleData
    } = useQuery<ScheduleDataType>(scheduleQueries.GET_ALL_BY_PATIENT_ID, async () => await scheduleService.getSchedulePatientId({ 
        patientId : props.patient.id!
    }));


    return{
        schedules: {
            scheduleData,
            isLoading: isLoadingScheduleData || isRefetchingScheduleData,
            refetchScheduleData
        },
        histories: {
            historyScheduleData,
            isLoading: isLoadingHistoryScheduleData || isRefetchingHistoryScheduleData,
            refetchHistoryScheduleData
        },
        dialogs: {
            showDialog,
            setShowDialog,
            scheduleReceiveDialog,
            setScheduleReceiveDialog
        }
    }
}