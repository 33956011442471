import { LoadingComponent } from '../loading';
import * as S from './styled';
import { EmptyTableProps } from "./type";

export const EmptyTableComponent = (props: EmptyTableProps) => {
    return (
        < S.EmptyTableContainer>
            <td colSpan={props.colSpan}>
                {props.isEmpty && !props.isLoading &&
                    <span className='empty-text'>{props.message || "Nenhum item encontrado"}</span>
                }
                {props.isLoading &&
                    <LoadingComponent containerHeight={200} />
                }
            </td>

        </S.EmptyTableContainer >
    );
};