import { useMutation } from "react-query";
import { useAuthService } from "../../../../../services/auth.service";
import { LoginSchemaType, loginSchema } from "./type";
import { useForm } from "react-hook-form";
import { zodResolver } from '@hookform/resolvers/zod';

export function useLoginHook(){
    const authService = useAuthService();
    const {mutate, isLoading} = useMutation((data: LoginSchemaType) => authService.login(data));
    const { register, handleSubmit, formState: { errors } } = useForm<LoginSchemaType>({ resolver: zodResolver(loginSchema)});
    
    function onSubmit(data: LoginSchemaType){
        mutate(data);
    };

    return {
        errors,
        isLoading,
        register,
        handleSubmit,
        onSubmit        
    };
}