import * as S from './styled';
import { useState } from 'react';
import { ButtonComponent } from '../../../components/button';
import { InputSearchComponent } from '../../../components/inputSearch';
import { TableComponent } from '../../../components/table';
// import { FormReceiveDialog } from '../formReceiveDialog';
import { FormUserDialog } from '../formUserDialog';
import { DeleteModalComponent } from '../../../components/deleteModal';

import add_icon from '../../../../assets/icons/add.svg';
import delete_icon from '../../../../assets/icons/delete.svg';
import edit_icon from '../../../../assets/icons/edit.svg';
import { useUserHook } from './hook';
import { DialogComponent } from '../../../components/dialog';
import { LoadingComponent } from '../../../components/loading';
import { EmptyTableComponent } from '../../../components/emptyTable';

export const UserPage = () => {

    const hook = useUserHook();

    return (
        <>
            <S.UserContainer>
                <span className='b1-bold'>Usuários</span>

                <S.UserSearchBar>
                    <InputSearchComponent refetch={hook.user.refetchUserData} setSearch={hook.user.setSearch} placeholder='Buscar pelo nome' />

                    <ButtonComponent buttonStyle='primary' width={150} onClick={() => hook.dialogs.setShowFormDialog(true)}>
                        <img src={add_icon} alt="Icone de adicionar" />
                        <span className='b3-regular'>Novo Usuário</span>
                    </ButtonComponent>

                </S.UserSearchBar>

                <S.UserTableSection>
                    <TableComponent 
                        setPage={hook.user.setPage}
                        page={hook.user.page}
                        totalItems={hook.user.userData?.count}
                    >
                        <thead className='b3-bold'>
                            <tr>
                                <th>Nome</th>
                                <th>CPF</th>
                                <th>Login</th>
                                <th></th>
                            </tr>
                        </thead>

                        {hook.user.isLoading || hook.user.userData?.count === 0 ?
                            <EmptyTableComponent colSpan={4} isEmpty={hook.user.userData?.count === 0 } isLoading={hook.user.isLoading}/> :
                            <tbody className='b3-regular'>
                                {hook.user.userData?.rows.map((user) => (
                                    <tr key={`user-${user.id}`}>
                                        <td>{user.name}</td>
                                        <td>{user.cpf}</td>
                                        <td>{user.login}</td>
                                        <td className='d-flex actions'>
                                            <img src={edit_icon} alt="Icone de editar" onClick={() => hook.dialogs.handleOpenFormModal(user)} />
                                            <img src={delete_icon} alt="Icone de apagar" onClick={() => hook.dialogs.handleOpenDeleteModal(user)} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        }
                    </TableComponent>
                </S.UserTableSection>
            </S.UserContainer>

            <DialogComponent
                isOpen={hook.dialogs.showFormDialog}
                onClose={hook.dialogs.handleCloseFormModal}
                width={330}
                title='Novo Usuário'
            >
                <FormUserDialog user={hook.user.selectedUser} onClose={hook.dialogs.handleCloseFormModal}/>
            </DialogComponent>

            <DialogComponent
                width={386}
                title={`Excluir Usuário`}
                onClose={hook.dialogs.handleCloseDeleteModal}
                isOpen={hook.dialogs.showDeleteDialog}
            >
                <DeleteModalComponent
                    title='Usuário'
                    onClose={hook.dialogs.handleCloseDeleteModal}
                    onDelete={hook.user.handleDeleteUser}
                    isLoading={hook.user.isLoadingDelete}
                />
            </DialogComponent>
        </>
    )
}