import { z } from "zod";

export type LoginType = {
    
}

export const loginSchema = z.object({
    login: z.string().min(1, 'O campo "Usuário" é obrigatório.'),
    password: z.string().min(1, 'O campo "Senha" é obrigatório.'),
});


export type LoginSchemaType = z.infer<typeof loginSchema>;