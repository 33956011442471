import { responseStatus, useHandleRequest } from "../api/api-request.service";
import { FinancialDataType, FinancialInterface } from "../models/financial.interface";
import { getDefaultQuery } from "../utils/query-client";

export const financialQueries = {
    EDIT: "FINANCIAL_EDIT",
    CREATE: "FINANCIAL_CREATE",
    DELETE: "FINANCIAL_DELETE",
    GET_ALL : "FINANCIAL_GET_ALL",
    GET_BY_ID : "FINANCIAL_GET_BY_ID",
}

interface GetAllFinancialProps{
    search: string;
    page: number;
    startDate: string;
    endDate: string;
}

interface GetOrDeleteFinancialByIdProps{
    id : number;
    onSuccess : Function;
}

interface CreateOrEditFinancialProps{
    payload : FinancialInterface;
    onSuccess : Function;
}

export function useFinancialService(){

    const handleRequest = useHandleRequest();

    async function createFinancial({payload, onSuccess} : CreateOrEditFinancialProps){
        const {status, data} = await handleRequest.handleRequest(`/financial/`, 'POST', payload);
        if(status === responseStatus.SUCCESS){
            onSuccess(data);
        } 
    }

    async function editFinancial({payload, onSuccess} : CreateOrEditFinancialProps){
        const {status, data} = await handleRequest.handleRequest(`/financial/${payload.id}`, 'PUT', payload);
        if(status === responseStatus.SUCCESS){
            onSuccess(data);
        } 
    }

    async function deleteFinancial({id, onSuccess} : GetOrDeleteFinancialByIdProps){
        const {status, data} = await handleRequest.handleRequest(`/financial/${id}`, 'DELETE');
        if(status === responseStatus.SUCCESS){
            onSuccess(data);
        } 
    }

    async function getAllFinancial({search, startDate, endDate,  page} : GetAllFinancialProps) : Promise<FinancialDataType>{
        const filters = [{ search }, { startDate }, { endDate }];
        let query = getDefaultQuery({ page, filters });
        const {status, data} = await handleRequest.handleRequest(`/financial/${query}`, 'GET');
        if(status === responseStatus.SUCCESS){
            return data;
        } 
    }

    async function getFinancialById({id} : GetOrDeleteFinancialByIdProps) : Promise<FinancialInterface | undefined>{
        const {status, data} = await handleRequest.handleRequest(`/financial/${id}`, 'GET');
        if(status === responseStatus.SUCCESS){
            return data;
        } 
    }

    return{  
        createFinancial,
        editFinancial,
        deleteFinancial,
        getAllFinancial,
        getFinancialById,
    }
}
