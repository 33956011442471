import styled from "styled-components";

interface SidebarItemProps {
    selected?: boolean;
}

export const SidebarContainer = styled.div`
    display: flex;
    width: 240px;
    height: 100vh;
    overflow-y: scroll;
    padding: 32px 32px 32px 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;

    background: var(--background-color);

    @media screen and (max-width: 600px){
        width: 50px;
        padding: 32px 0 32px 4px;
    }

`;

export const SidebarItems =  styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    
`;

export const SidebarItem =  styled.div<SidebarItemProps>`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 4px 6px;
    width: 100%;
    gap: 8px;
    border-radius: 4px;
    ${props=>
        props.selected && 
        `
            background-color: var(--blue-color-20);
            span{
                color: var(--blue-color-100) !important;
                font-weight: 700;
            }
        `
    }

    &:hover{ 
        background-color: var(--blue-color-20);
        span{
            color: var(--blue-color-100);
            font-weight: 700;
        }        
    }

    @media screen and (max-width: 600px){
        align-items: center;
        justify-content: center;
        span{
            display: none;
        }
    }
`;

export const SidebarLogo = styled.div`
    img{
        width: 185px;
        height: 77px;
    }

    @media screen and (max-width: 600px){
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 25px;
            height: 12px;
        }
    }

`;

export const SidebarHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
    align-self: stretch;
`;

export const SidebarFoot = styled.div`

`;