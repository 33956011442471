import * as S from './styled';
import arrow_down_icon from  '../../../assets/icons/arrow_down.svg';
import arrow_down_black_icon from  '../../../assets/icons/arrow_down_black.svg';
import arrow_down_green_icon from  '../../../assets/icons/arrow_down_green.svg';
import arrow_down_red_icon from  '../../../assets/icons/arrow_down_red.svg';
import { ArrowType } from './type';

export const ArrowComponent = (props : ArrowType)=>{
    return (
        <S.ArrowContainer className={props.className} direction={props.direction} onClick={props.onClick}>
            {props.color === 'blue' &&
                <img src={arrow_down_icon} alt="Icone Seta"/>
            }

            {props.color === 'green' &&
                <img src={arrow_down_green_icon} alt="Icone Seta"/>
            }

            {props.color === 'red' &&
                <img src={arrow_down_red_icon} alt="Icone Seta"/>
            }

            {props.color === 'black' &&
                <img src={arrow_down_black_icon} alt="Icone Seta"/>
            }
        </S.ArrowContainer>
    );
};