import styled from "styled-components";

export const FormReceiveDialogContainer = styled.div`

`;

export const FormReceiveDialogContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;