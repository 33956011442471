import { z } from 'zod';
import { isCPFValid } from '../../utils/validations';

export const patientSchema = z.object({
    id: z.number().optional(),
    name: z.string().min(1, 'O campo "Nome" é obrigatório'),
    cpf: z.union([
        z.null(),
        z.string()
            .refine(cpf => cpf ? isCPFValid(cpf) : true, { message: 'Informe um cpf válido' })
            .optional(),
    ]),
    birthDate: z.union([
        z.date(),
        z.string().optional()
    ]),
    phoneNumber: z.string().min(1, {message: 'O campo "Contato 1" é obrigatório'}),
    secondPhoneNumber: z.string().optional(),
    street: z.string().min(1, {message: 'O campo "Rua" é obrigatório.'}),
    number: z.string().optional(),
    neighborhook: z.string().min(1, {message: 'O campo "Bairro" é obrigatório.'}),
    city: z.string().min(1, {message: 'O campo "Cidade" é obrigatório.'}),    
});

export type PatientSchemaType = z.infer<typeof patientSchema>;
