import styled from "styled-components";

export const DeleteModalContainer = styled.div`
   display: flex;
   flex-direction: column;
   gap: 24px;
`;

export const DeleteModalAction = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 16px;
`