import styled from "styled-components";

export const FormScheduleDialogContainer = styled.div`

`;

export const FormScheduleDialogContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    input[type="date"], input[type="time"]{
        padding: 0 10px;
    }

    .check-container{
        display: flex;
        gap: 30px;
        
        input{
            width: 16px !important;
            height: 16px !important;
        }

        .check-in, .check-out{
            display: flex;
            gap: 10px;
            align-items: center;
        }

        .check-in>span{
            color: var(--green-color) !important;
        }

        .check-out>span{
            color: var(--orange-color-100) !important;
        }
    }
`;

export const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        width: '100%',
        height: '32px',
        borderRadius: '4px',
        border: state.isFocused ? '1px solid blue' : '1px solid var(--input-border-color)',
        background: 'var(--card-bg-color)',
    }),
    valueContainer: (provided: any) => ({
        height: '32px',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '10px'
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    clearIndicator: (provided: any) => ({
        ...provided,
        display: 'none',
    }),
    placeholder:  (provided: any) => ({
        ...provided,
        display: 'none',
    }),
   
};
