import { z } from 'zod';
import { isCPFValid } from '../../utils/validations';

export const userSchema = z.object({
    id: z.number().optional(),
    name: z.string().min(1, 'O campo "Nome" é obrigatório'),
    cpf:
        z.string().min(11, {message : "O campo 'CPF' precisa de pelo menos 11 numeros"}).refine(cpf => cpf ? isCPFValid(cpf) : true, { message: 'Informe um cpf válido' }),
    login: z.string()
        .min(1, 'O campo "Login" é obrigatório')
        .min(6, 'O campo "Login" precisa de pelo menos 6 letras')
        .refine(login => /^[^\d]+$/.test(login), {
            message: 'O campo "Login" deve possuir apenas letras',
            path: ['login']
        }),
    password: z.string()
        .min(1, 'O campo "Senha" é obrigatório')
        .min(6, 'O campo "Senha" precisa de pelo menos 6 letras ou números'),
});

export const userSchemaEdit = userSchema.omit({ password: true }).merge(z.object({
    password: z.union([
        z.string().optional(),
        z.string()
            .min(1, 'O campo "Senha" é obrigatório')
            .min(6, 'O campo "Senha" precisa de pelo menos 6 letras ou números'),
    ])
}));

export type UserSchemaType = z.infer<typeof userSchema | typeof userSchemaEdit>;
