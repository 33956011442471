import * as S from './styled';
import notification_icon from  '../../../assets/icons/notification.svg';
import profile_image from '../../../assets/images/profile.png';
import { useTopBarHook } from './hook';

export const TopBarComponent = ()=>{

    const hook = useTopBarHook();

    return (
        <S.TopBarContainer>
            <S.TopBarTitle className='b3-bold'>
                <img src={hook.sidebarItem.activeIcon} alt="Icone da pagina ativa" />
                <span className='b3-bold'>{hook.sidebarItem.name}</span>
            </S.TopBarTitle>

            <S.TopBarContent>
                {/* <S.TopBarNotifications>
                    <img src={notification_icon} alt="Icone de notificações"/>
                </S.TopBarNotifications> */}
                <S.TopBarProfile className='b3-bold'>
                    <S.TopBarProfilePic>
                        <img src={profile_image} alt="Foto de perfil do usuário"/>
                    </S.TopBarProfilePic>
                    <span>{hook.userName?.[0]} {hook.userName?.[1]}</span>
                </S.TopBarProfile>
            </S.TopBarContent>
        </S.TopBarContainer>
    );
};