import { responseStatus, useHandleRequest } from "../api/api-request.service";
import { PatientDataType, PatientInterface } from "../models/patient.interface";
import { getDefaultQuery } from "../utils/query-client";

export const patientQueries = {
    EDIT: "PATIENT_EDIT",
    CREATE: "PATIENT_CREATE",
    DELETE: "PATIENT_DELETE",
    GET_ALL : "PATIENT_GET_ALL",
    GET_ALL_OPTIONS : "PATIENT_GET_ALL_OPTIONS",
    GET_BY_ID : "PATIENT_GET_BY_ID",
    GET_BIRTHDAY_TODAY : "GET_BIRTHDAY_TODAY",
    GET_BIRTHDAY_MONTH : "GET_BIRTHDAY_MONTH",
}

interface GetAllPatientProps{
    search: string;
    page: number;
}

interface GetOrDeletePatientByIdProps{
    id : number;
    onSuccess : Function;
}

interface CreateOrEditPatientProps{
    payload : PatientInterface;
    onSuccess : Function;
}

export function usePatientService(){

    const handleRequest = useHandleRequest();

    async function createPatient({payload, onSuccess} : CreateOrEditPatientProps){
        const {status, data} = await handleRequest.handleRequest(`/patient/`, 'POST', payload);
        if(status === responseStatus.SUCCESS){
            onSuccess(data);
        } 
    }

    async function editPatient({payload, onSuccess} : CreateOrEditPatientProps){
        const {status, data} = await handleRequest.handleRequest(`/patient/${payload.id}`, 'PUT', payload);
        if(status === responseStatus.SUCCESS){
            onSuccess(data);
        } 
    }

    async function deletePatient({id, onSuccess} : GetOrDeletePatientByIdProps){
        const {status, data} = await handleRequest.handleRequest(`/patient/${id}`, 'DELETE');
        if(status === responseStatus.SUCCESS){
            onSuccess(data);
        } 
    }

    async function getAllPatient({search, page} : GetAllPatientProps) : Promise<PatientDataType>{
        const filters = [{name : search}];
        let query = getDefaultQuery({ page, filters });
        const {status, data} = await handleRequest.handleRequest(`/patient/${query}`, 'GET');
        if(status === responseStatus.SUCCESS){
            return data;
        } 
    }

    async function getByrthdayTodayPatient() : Promise<PatientDataType>{
        const {status, data} = await handleRequest.handleRequest(`/patient/birthday/today`, 'GET');
        if(status === responseStatus.SUCCESS){
            return data;
        } 
    }

    async function getByrthdayMonthPatient() : Promise<PatientDataType>{
        const {status, data} = await handleRequest.handleRequest(`/patient/birthday/month`, 'GET');
        if(status === responseStatus.SUCCESS){
            return data;
        } 
    }

    async function getPatientOptions({search, page} : GetAllPatientProps) : Promise<{value: string, label: string}[] | undefined>{
        const filters = [{ search }];
        let query = getDefaultQuery({ page, filters });
        const {status, data} = await handleRequest.handleRequest(`/patient/${query}`, 'GET');
        if(status === responseStatus.SUCCESS){
            return data.rows.map((patient : PatientInterface)=>({value: patient.id!.toString(), label: patient.name}));
        } 
    }

    async function getPatientById({id} : GetOrDeletePatientByIdProps) : Promise<PatientInterface | undefined>{
        const {status, data} = await handleRequest.handleRequest(`/patient/${id}`, 'GET');
        if(status === responseStatus.SUCCESS){
            return data;
        } 
    }

    return{  
        createPatient,
        editPatient,
        deletePatient,
        getAllPatient,
        getPatientById,
        getPatientOptions,
        getByrthdayTodayPatient,
        getByrthdayMonthPatient
    }
}
