import styled from "styled-components";

export const OverviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    .empty{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 500px;
    }
`;

export const OverviewListCards = styled.div`
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
`;