import * as S from './styled';
import { ButtonComponent } from '../../../components/button';
import { InputSearchComponent } from '../../../components/inputSearch';
import { TableComponent } from '../../../components/table';
import { DeleteModalComponent } from '../../../components/deleteModal';
import { ArrowComponent } from '../../../components/arrow';
import { FormFinancialDialog } from '../formFinancialDialog';
import { useFinancialHook } from './hook';
import { formatDate } from 'date-fns';
import { formatPrice } from '../../../../utils/validations';
import { EmptyTableComponent } from '../../../components/emptyTable';
import { DialogComponent } from '../../../components/dialog';

import add_icon from '../../../../assets/icons/add.svg';
import delete_icon from '../../../../assets/icons/delete.svg';
import edit_icon from '../../../../assets/icons/edit.svg';

export const FinancialPage = () => {

    const hook = useFinancialHook();

    return (
        <>
            <S.FinancialContainer>
                <span className='b1-bold'>Fluxo de caixa</span>

                <S.FinancialSearchBar>
                    <S.FinancialSearchBarInputs>
                        <InputSearchComponent placeholder='Buscar' setSearch={hook.financial.setSearch} refetch={hook.financial.refetchFinancialData} />
                        <input type="date" value={hook.financial.startDate} onChange={(e) => hook.financial.setStartDate(e.target.value)} />
                        <input type="date" value={hook.financial.endDate} onChange={(e) => hook.financial.setEndDate(e.target.value)} />
                    </S.FinancialSearchBarInputs>

                    <ButtonComponent buttonStyle='primary' width={170} onClick={() => hook.dialogs.setShowFormDialog(true)}>
                        <img src={add_icon} alt="Icone de adicionar" />
                        <span className='b3-regular'>Novo Lançamento</span>
                    </ButtonComponent>

                </S.FinancialSearchBar>

                <S.FinancialTableSection>
                    <TableComponent
                        setPage={hook.financial.setPage}
                        page={hook.financial.page}
                        totalItems={hook.financial.financialData?.count}
                    >
                        <thead className='b3-bold'>
                            <tr>
                                <th>Observação</th>
                                <th>Data</th>
                                <th>Valor</th>
                                <th></th>
                            </tr>
                        </thead>

                        {hook.financial.isLoading || hook.financial.financialData?.count === 0 ?
                            <EmptyTableComponent colSpan={4} isEmpty={hook.financial.financialData?.count === 0} isLoading={hook.financial.isLoading} /> :
                            <tbody className='b3-regular'>
                                {hook.financial.financialData?.rows.map((release) => {
                                    const isIN = release.type === 'IN';
                                    return (

                                        <tr key={`release-${release.id}`}>
                                            <td>{release.observation}</td>
                                            <td>{formatDate(release.date, 'dd/MM/yyyy HH:mm')}</td>
                                            <td>
                                                <div className={`value ${isIN ? 'up' : 'down'}`}>
                                                    <ArrowComponent direction={isIN ? 'up' : 'down'} color={isIN ? 'green' : 'red'} />
                                                    <span>{formatPrice(parseFloat(release.value!))}</span>
                                                </div>
                                            </td>
                                            <td className='d-flex actions'>
                                                {release.canDelete &&
                                                    <>
                                                        <img src={edit_icon} alt="Icone de editar" onClick={() => hook.dialogs.handleOpenFormModal(release)} />
                                                        <img src={delete_icon} alt="Icone de apagar" onClick={() => hook.dialogs.handleOpenDeleteModal(release)} />
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}

                                <tr>
                                    <td><b>Total</b></td>
                                    <td>
                                        <div className='value up'>
                                            <b>Entradas:</b>
                                            <ArrowComponent direction='up' color='green' />
                                            <span>{formatPrice(parseFloat(hook.financial.financialData?.totalIn?.toString() || '0'))}</span>
                                        </div>
                                    </td>

                                    <td>
                                        <div className='value down'>
                                            <b>Saídas:</b>
                                            <ArrowComponent direction='down' color='red' />
                                            <span>{formatPrice(parseFloat(hook.financial.financialData?.totalOut?.toString() || '0'))}</span>
                                        </div>
                                    </td>

                                    <td>
                                        <div className={`value actions ${hook.financial.getBalance() < 0 ? 'down' : 'up'}`}>
                                            <b>Saldo:</b>
                                            <ArrowComponent direction={hook.financial.getBalance() < 0 ? 'down' : 'up'} color={hook.financial.getBalance() < 0 ? 'red' : 'green'} />
                                            <span>{formatPrice(hook.financial.getBalance())}</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        }
                    </TableComponent>
                </S.FinancialTableSection>
            </S.FinancialContainer>

            <DialogComponent
                isOpen={hook.dialogs.showFormDialog}
                onClose={hook.dialogs.handleCloseFormModal}
                width={330}
                title='Novo Lançamento'

            >
                <FormFinancialDialog financial={hook.financial.selectedFinancial} onClose={hook.dialogs.handleCloseFormModal} />
            </DialogComponent>

            <DialogComponent
                width={386}
                title={`Excluir Lançamento`}
                onClose={hook.dialogs.handleCloseDeleteModal}
                isOpen={hook.dialogs.showDeleteDialog}
            >
                <DeleteModalComponent
                    title='Lançamento'
                    onClose={hook.dialogs.handleCloseDeleteModal}
                    onDelete={hook.financial.handleDeleteFinancial}
                    isLoading={hook.financial.isLoadingDelete}
                />
            </DialogComponent>

        </>
    )
}