import { AbsInterface } from "./abs.interface";
import { PatientInterface } from "./patient.interface";
import { UserInterface } from "./user.interface";

export const ScheduleStatus = {
    SCHEDULED : "SCHEDULED",
    CANCELED : "CANCELED",
    CONCLUDED : "CONCLUDED"
};

export const ScheduleStatusList = {
    SCHEDULED : "Agendado",
    CANCELED : "Cancelado",
    CONCLUDED : "Concluído"
};

export const SchedulePaymentStatus = {
    "PENDING" : "PENDING",
    "PAID OUT" : "PAID OUT"
};

export interface ScheduleInterface extends AbsInterface {
    scheduleDate: Date | string;
    hour: Date | string;
    serviceDate: Date;
    price: string;
    observation?: string;
    finalizeObservation?: string;
    status: keyof typeof ScheduleStatus;
    paymentStatus: keyof typeof SchedulePaymentStatus;
    patient? : PatientInterface;
    fkPatientId : number;
    createdBy? : UserInterface;
    fkCreatedById? : number;
}

export type ScheduleDataType = {
    count: number;
    rows: ScheduleInterface[]    
} | undefined;

export type ScheduleGroupDateDataType = {
    rows : {[date: string]: ScheduleInterface[]}    
} | undefined;