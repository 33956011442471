import { useEffect, useState } from "react";
import { InputSearchHookType } from "./type";

export function useInputSearchHook(props : InputSearchHookType){
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const [localSearch, setLocalSearch] = useState('');

    function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
        setLocalSearch(e.target.value);
        if(props.setSearch){
            props.setSearch(e.target.value);
        }
    }

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedSearch(localSearch);
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [localSearch]);

    useEffect(() => {
        if(props.refetch){
            props.refetch();
        }
    }, [debouncedSearch]);

    return{
        localSearch,
        handleSearch
    }
}
