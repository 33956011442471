import { createSlice } from '@reduxjs/toolkit';

type SideBarItemType = {
    name: string;
    activeIcon: string;
}

const initialState = {
    toastMessage: '',
    showPage : true,
    sideBarItem:  {
        name: '',
        activeIcon: '',
    },
}

const generalSlice = createSlice({
    name: 'General',
    initialState,
    reducers: {
        setToastMessage(state, action: { payload: string }) {
            state.toastMessage = action.payload;
        },

        pageTransition(state, action: { payload: boolean }) {
            state.showPage = action.payload;
        },

        setSideBarItem(state, action: {payload : SideBarItemType}){
            state.sideBarItem = action.payload;
        }
    },
});

export const { setToastMessage, pageTransition, setSideBarItem } = generalSlice.actions;
export const generalReducer = generalSlice.reducer;
