import styled from "styled-components";



export const CardBirthdayPersonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 244px;
    height: 126px;
    padding: 15px 30px;
    border: 1px solid var(--grey-color-40);
    border-radius: 8px;

    span{
        color: var(--blue-color-100);
    }

    img{
        width: 23px;
    }
`;

export const CardBirthdayPersonInfos = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
