import styled from "styled-components";

export const AppContainer = styled.main`
    display: flex;
`;

export const AppMain = styled.main`
    background-color: var(--white-2-color);
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
`;

export const AppContent = styled.section`
    padding: 32px;
`;