import styled from "styled-components";

interface DialogContentProps {
    "data-width": number;
    "data-height"?: number;
}

export const DialogContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--grey-color-20-opacity);
    z-index: 6;

`;

export const DialogContent = styled.div<DialogContentProps>`
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow-y: scroll;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: ${props=>props["data-width"]}px;
    height: ${props=>props["data-height"] ? `${props["data-height"]}px` : 'auto'};
    max-height: calc(100vh - 30px);

    padding: 20px 10px 20px 20px;

    border-radius: 16px;
    border: 1px solid var(--card-border-color);
    background: var(--card-bg-color);
`;

export const DialogTitle =  styled.div`
    display: flex;
    width: 100%;

    img{
        position: absolute;
        right: 20px;
        cursor: pointer;
        padding: 6px;
        border-radius: 4px;

        &:hover{
            background-color: var(--card-border-color);
        }
    }
`;

export const DialogChildren = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 0 8px;

`;