import { useMutation } from "react-query";
import { PaymentSchemaType, paymentSchema } from "../../../models/schemas/payment.schema";
import { scheduleQueries, useScheduleService } from "../../../services/schedule.service";
import { PaymentInterface } from "../../../models/payment.interface";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { queryClient } from "../../../utils/query-client";
import { useEffect, useState } from "react";
import { FormReceiveDialogHookType } from "./type";
import { formatPrice } from "../../../utils/validations";

export function useFormReceiveDialogHook(props: FormReceiveDialogHookType) {
    const [total, setTotal] = useState(0);
    const scheduleService = useScheduleService();

    const { mutate, isLoading: isLoadingSubmit } = useMutation(
        async (payload: PaymentInterface) =>
            await scheduleService.payment({ payload, onSuccess })
    );

    const paymentForm = useForm<PaymentSchemaType>({
        resolver: zodResolver(paymentSchema)
    });

    const watchDiscounts = paymentForm.watch('discounts');
    const watchAddition = paymentForm.watch('addition');

    function onSubmit(data: PaymentSchemaType) {
        mutate({
            ...data,
            fkScheduleId: props.schedule?.id ?? 0
        } as PaymentInterface);
    }

    function onSuccess() {
        queryClient.refetchQueries(scheduleQueries.GET_ALL);
        queryClient.refetchQueries(scheduleQueries.GET_PENDING);
        queryClient.refetchQueries(scheduleQueries.GET_ALL_BY_PATIENT_ID);
        queryClient.refetchQueries(scheduleQueries.GET_BY_PATIENT_ID);
        queryClient.refetchQueries(scheduleQueries.GET_ALL_GROUP_DATE);
      
        props.onClose();
        
    }

    useEffect(() => {
        const price = parseFloat(props.schedule?.price || '0');
        const discounts = parseInt(watchDiscounts?.replace(/[^\d]/g, '')) / 100 || 0;
        const addition = parseInt(watchAddition?.replace(/[^\d]/g, '')) / 100 || 0;
        
        const some = (price - discounts + addition);
        paymentForm.setValue('total', isNaN(some) ? 'R$ 0,00' : formatPrice(some));
        setTotal(total);

    }, [props.schedule, paymentForm, watchAddition, watchDiscounts]);

    return {
        paymentForm,
        isLoadingSubmit,
        total,
        onSubmit,
    }
}