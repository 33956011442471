import { setToastMessage } from "../redux/features/general-slice";
import { useAppDispatch } from "../redux/store";
import { LocalToken } from "../utils/local-token";
import api from "./api";

export const responseStatus = {
    SUCCESS : 'SUCCESS',
    NOT_FOUND : 'NOT_FOUND',
    ERROR : 'ERROR'
}

export function useHandleRequest(){
    const dispatch = useAppDispatch();

    function cleanData(data: any) {
        if (typeof data !== 'object' || data === null) return data;

        const cleanedData: any = {};
        for (const key in data) {
            if (data[key] !== '' && data[key] !== null && data[key] !== undefined) {
                cleanedData[key] = cleanData(data[key]);
            }
        }
        return cleanedData;
    }

    async function handleRequest(apiEndpoint: string, method: string, data?: any, errorMessage?: string) {
        try {
            let response;

            // Limpa o objeto data antes de enviar
            if (data) {
                data = cleanData(data);
            }

            switch (method.toUpperCase()) {
                case 'GET':
                    response = await api.get(apiEndpoint);
                    break;
                case 'POST':
                    response = await api.post(apiEndpoint, data);
                    break;
                case 'PUT':
                    response = await api.put(apiEndpoint, data);
                    break;
                case 'DELETE':
                    response = await api.delete(apiEndpoint);
                    break;
                default:
                    throw new Error('Invalid HTTP method');
            }
    
            if (response.status >= 200 && response.status < 300) {
                return {
                    status: responseStatus.SUCCESS,
                    data: response.data,
                };
            } else {
                throw response;
            }

        } catch (err: any) {
            const error = err.response.data;

            if (error.error === 'AuthorizationError') {
                LocalToken.clearStoredToken();
                window.location.reload();
            } else {
                let messageError = error.error === 'Error' ? error.message : 'Ocorreu um erro inesperado';
                dispatch(setToastMessage(messageError));
                setTimeout(() => {
                    dispatch(setToastMessage(''));
                }, 5000);
            }

            return {
                status: err.response.status === 404 ? responseStatus.NOT_FOUND : responseStatus.ERROR,
                data: errorMessage ? errorMessage : err,
            };
        }
    }

    return {
        handleRequest,
        responseStatus
    }
}
