import * as S from './styled';
import { FormComponent } from '../../../components/form';
import { ButtonComponent } from '../../../components/button';
import { useFormPatientDialogHook } from './hook';
import { FormPatientDialogType } from './type';

export const FormPatientDialog = (props: FormPatientDialogType) => {
    const hook = useFormPatientDialogHook(props);
    return (
        <S.FormPatientDialogContainer>

            <FormComponent onSubmit={hook.patientForm.handleSubmit(hook.onSubmit)}>
                <S.FormPatientDialogContent>
                    <div className='form-group'>
                        <label htmlFor="name" className="c1-regular">Nome completo do paciente: *</label>
                        <input type='text' id='name' placeholder='Nome completo do paciente' {...hook.patientForm.register('name')} />
                        <span className='error'>{hook.patientForm.formState.errors.name?.message}</span>
                    </div>

                    <div className='form-row'>
                        <div className='form-group middle'>
                            <label htmlFor="cpf" className="c1-regular">CPF:</label>
                            <input type='text' id='cpf' placeholder='CPF' {...hook.patientForm.register('cpf')} />
                            <span className='error'>{hook.patientForm.formState.errors.cpf?.message}</span>
                        </div>

                        <div className='form-group middle'>
                            <label htmlFor="birthDate" className="c1-regular">Data de Nascimento:</label>
                            <input type='date' id='birthDate' placeholder='Data de Nascimento' {...hook.patientForm.register('birthDate')} />
                            <span className='error'>{hook.patientForm.formState.errors.birthDate?.message}</span>
                        </div>
                    </div>

                    <div className='form-row'>
                        <div className='form-group middle'>
                            <label htmlFor="contact" className="c1-regular">Contato 1: *</label>
                            <input type='text' id='phoneNumber' placeholder='Contato 1' {...hook.patientForm.register('phoneNumber')} />
                            <span className='error'>{hook.patientForm.formState.errors.phoneNumber?.message}</span>
                        </div>

                        <div className='form-group middle'>
                            <label htmlFor="contactSecondary" className="c1-regular">Contato 2:</label>
                            <input type='text' id='secondPhoneNumber' placeholder='Contato 2' {...hook.patientForm.register('secondPhoneNumber')} />
                            <span className='error'>{hook.patientForm.formState.errors.secondPhoneNumber?.message}</span>
                        </div>
                    </div>

                    <div className='form-row'>
                        <div className='form-group long'>
                            <label htmlFor="contact" className="c1-regular">Rua: *</label>
                            <input type='text' id='street' placeholder='Rua' {...hook.patientForm.register('street')} />
                            <span className='error'>{hook.patientForm.formState.errors.street?.message}</span>
                        </div>

                        <div className='form-group small'>
                            <label htmlFor="contactSecondary" className="c1-regular">Número:</label>
                            <input type='text' id='number' placeholder='Número' {...hook.patientForm.register('number')} />
                            <span className='error'>{hook.patientForm.formState.errors.number?.message}</span>
                        </div>
                    </div>

                    <div className='form-row'>
                        <div className='form-group small'>
                            <label htmlFor="neighborhood" className="c1-regular">Bairro: *</label>
                            <input type='text' id='neighborhook' placeholder='Bairro' {...hook.patientForm.register('neighborhook')} />
                            <span className='error'>{hook.patientForm.formState.errors.neighborhook?.message}</span>
                        </div>

                        <div className='form-group long'>
                            <label htmlFor="city" className="c1-regular">Cidade: *</label>
                            <input type='text' id='city' placeholder='Cidade' {...hook.patientForm.register('city')} />
                            <span className='error'>{hook.patientForm.formState.errors.city?.message}</span>
                        </div>
                    </div>

                </S.FormPatientDialogContent>

                <ButtonComponent buttonStyle='primary' type='submit' disabled={hook.isLoadingSubmit}>
                    <span className="b3-bold">
                        {hook.isLoadingSubmit ? 'Salvando...' : 'Salvar paciente'}
                    </span>
                </ButtonComponent>

            </FormComponent>
        </S.FormPatientDialogContainer>
    );
};