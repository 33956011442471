import styled from "styled-components";

interface InputSearchContainerProps{
    width?: number
}

export const InputSearchContainer = styled.div<InputSearchContainerProps>`
    display: flex;
    width: ${props=>props.width ? props.width : 418}px;
    height: 40px;
    padding: 8px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;

    border-radius: 8px;
    background: var(--background-color);

    input{
        height: 100%;
        width: 100%;
        border: none;
        background-color: transparent;
        outline: none;
        /* color: var(--grey-color-40); */
    }
`;