import CurrencyInput from 'react-currency-input-field';
import * as S from './styled';
import { InputCurrencyType } from './type';
import { useInputCurrencyHook } from './hook';

export const InputCurrencyComponent = (props : InputCurrencyType)=>{

    return(
        <S.InputCurrencyContainer>
            <CurrencyInput
                id={props.id}
                disabled={props.disabled}
                decimalsLimit={2}
                allowDecimals={true}
                disableGroupSeparators={true}
                decimalSeparator=","
                groupSeparator="."
                decimalScale={2} 
                fixedDecimalLength={2}
                prefix='R$ '
                intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                {...props.register(props.id)}
                defaultValue={props.value}
                allowNegativeValue={props.allowNegativeValue}
            />
        </S.InputCurrencyContainer>
    )
}