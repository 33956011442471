import * as S from './styled';

import { CardBirthdayPersonComponent } from '../../../components/cardBirthdayPerson';
import { CardScheduleComponent } from '../../../components/cardSchedule';
import { useOverviewHook } from './hook';
import { LoadingComponent } from '../../../components/loading';
import { EmptyTableComponent } from '../../../components/emptyTable';


export const OverviewPage = () => {

    const hook = useOverviewHook();

    return (
        <>
            
            {hook.general.isLoading ? <LoadingComponent containerHeight={500} /> :
                <S.OverviewContainer>
                    {hook.general.isEmptyData() && <span className='b3-regular empty'>Ainda não há dados suficientes</span> }
                    {hook.birthDayToday.birthDayTodayData?.count === 0 ?
                        <></> :
                        <>
                            <span className='b1-bold'>Aniversariantes do Dia</span>
                            <S.OverviewListCards>
                                {hook.birthDayToday.birthDayTodayData?.rows.map(patient => (
                                    <CardBirthdayPersonComponent patient={patient} />
                                ))}
                            </S.OverviewListCards>
                        </>
                    }


                    {hook.birthDayMonth.birthDayMonthData?.count === 0 ?
                        <></> :
                        <>
                            <span className='b1-bold'>Aniversariantes do Mês</span>
                            <S.OverviewListCards>
                                {hook.birthDayMonth.birthDayMonthData?.rows.map(patient => (
                                    <CardBirthdayPersonComponent patient={patient} />
                                ))}
                            </S.OverviewListCards>
                        </>
                    }


                    
                    
                    {hook.schedules.schedulesData?.count === 0 ?
                        <></> :
                        <>
                            <span className='b1-bold'>Agendamentos de hoje</span>
                            <S.OverviewListCards>
                                {hook.schedules.schedulesData?.rows.map(schedule => (
                                    <CardScheduleComponent schedule={schedule} today={true}/> 
                                ))}
                            </S.OverviewListCards>
                        </>
                    }
                    
                </S.OverviewContainer>
            }
        </>
    )
}