import { FETCH_LIMIT_TABLE } from '../../../utils/query-client';
import { ArrowComponent } from '../arrow';
import { usePaginationHook } from './hook';
import * as S from './styled';
import { PaginationType } from './type';

export const PaginationComponent = (props: PaginationType) => {
    const hook = usePaginationHook(props);
    return (
        <>
            {props.totalItems === 0 ?
                <></> :
                <S.PaginationContainer>
                    <span className='b3-bold'>Página {props.page}<span className="b3-regular"> de {props?.totalItems ? Math.ceil(props.totalItems / FETCH_LIMIT_TABLE) : 1}</span></span>
                    <S.PaginationAction>
                        <ArrowComponent direction={'left'} color={'black'} onClick={hook.actions.previus} />
                        <ArrowComponent direction={'right'} color={'black'} onClick={hook.actions.next} />
                    </S.PaginationAction>
                </S.PaginationContainer>

            }
        </>
    );
};