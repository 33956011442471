import * as S from './styled';
import search_icon from "../../../assets/icons/search.svg";
import { InputSearchType } from './type';
import { useInputSearchHook } from './hook';

export const InputSearchComponent = (props : InputSearchType)=>{

    const hook = useInputSearchHook(props);

    return(
        <S.InputSearchContainer width={props.width}>
            <img src={search_icon} alt="Icone de busca"/>
            <input className='c1-regular' placeholder={props.placeholder} value={hook.localSearch} onChange={hook.handleSearch}/>
        </S.InputSearchContainer>
    )
}