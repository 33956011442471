import styled from "styled-components";

export const LoginContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: var(--blue-color-60);
`;

export const LoginFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    .form-group{
        width: 100% !important;

        input{
            width: 300px;
        }
    }
`;

export const LoginContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 420px;
    background-color: var(--white-color);
    border-radius: 16px;
    margin: 20px;

    padding: 50px 15px;
    
    .logo{
        width: 110px;
    }

    
`;

