import { useMutation } from "react-query";
import { scheduleQueries, useScheduleService } from "../../../services/schedule.service";
import { PaymentInterface } from "../../../models/payment.interface";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { queryClient } from "../../../utils/query-client";
import { useEffect, useState } from "react";
import { FormFinalizeDialogHookType } from "./type";
import { formatPrice } from "../../../utils/validations";
import { FinalizeSchemaType, finalizeSchema } from "../../../models/schemas/finalize.schema";
import { FinalizeInterface } from "../../../models/finalize.interface";

export function useFormFinalizeDialogHook(props: FormFinalizeDialogHookType) {
    const [total, setTotal] = useState(0);
    const [receive, setReceive] = useState(false);
    const scheduleService = useScheduleService();

    const { mutate, isLoading: isLoadingSubmit } = useMutation(
        async (payload: FinalizeInterface) =>
            await scheduleService.finalize({ payload, onSuccess })
    );

    const paymentForm = useForm<FinalizeSchemaType>({
        resolver: zodResolver(receive ? finalizeSchema : finalizeSchema.omit({
            discounts : true,
            addition : true,
            total: true
        }))
    });

    const watchDiscounts = paymentForm.watch('discounts');
    const watchAddition = paymentForm.watch('addition');

    function onSubmit(data: FinalizeSchemaType) {
        mutate({
            ...data,
            fkScheduleId: props.schedule?.id ?? 0,
            receive
        } as FinalizeInterface);
    }

    function onSuccess() {
        queryClient.refetchQueries(scheduleQueries.GET_ALL);
        queryClient.refetchQueries(scheduleQueries.GET_ALL_BY_PATIENT_ID);
        queryClient.refetchQueries(scheduleQueries.GET_BY_PATIENT_ID);
        queryClient.refetchQueries(scheduleQueries.GET_ALL_GROUP_DATE);
        queryClient.refetchQueries(scheduleQueries.GET_ALL_DUE_TODAY);
        props.onClose();
        
    }

    useEffect(() => {
        const price = parseFloat(props.schedule?.price || '0');
        const discounts = parseInt(watchDiscounts?.replace(/[^\d]/g, '')) / 100 || 0;
        const addition = parseInt(watchAddition?.replace(/[^\d]/g, '')) / 100 || 0;
        
        const some = (price - discounts + addition);
        paymentForm.setValue('total', isNaN(some) ? 'R$ 0,00' : formatPrice(some));
        setTotal(total);

    }, [props.schedule, paymentForm, watchAddition, watchDiscounts]);

    return {
        paymentForm,
        isLoadingSubmit,
        total,
        onSubmit,
        receive, 
        setReceive
    }
}