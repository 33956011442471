import { FETCH_LIMIT_TABLE } from "../../../utils/query-client";
import { PaginationHookType } from "./type";

export function usePaginationHook(props: PaginationHookType) {
    function next() {
        if (props.page && props.totalItems && props.setPage) {
            if (props.page < Math.ceil(props.totalItems / FETCH_LIMIT_TABLE)) {
                props.setPage(props.page + 1);
            }
        }
    }

    function previus() {
        if (props.page && props.totalItems && props.setPage) {
            if (props.page > 1) {
                props.setPage(props.page - 1);
            }
        }
    }

    return {
        actions: {
            next,
            previus
        }
    }
}