import styled from "styled-components";

export const TopBarContainer = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    width: 100%;
    padding: 16px 24px 16px 32px;
    justify-content: space-between;
    align-items: center;    

    border-bottom: 1px solid var(--grey-color-40, #B3B4B4);
    background: var(--background-color);

    z-index: 6;
`;

export const TopBarTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    color: var(--blue-color-100);
`;

export const TopBarContent = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const TopBarNotifications = styled.div`
    display: flex;
    padding: 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 8px;
    border: 1px solid var(--grey-color-20);
`;

export const TopBarProfile = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    color: var(--grey-color-80);
`;

export const TopBarProfilePic = styled.div`
    display: flex;
    width: 32px;
    height: 32px;
    padding: 2px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 96px;
    border: 1px solid var(--grey-color-20);

    
    img{
        flex: 1 0 0;
        align-self: stretch;
        overflow: hidden;
        border-radius: 96px;
    }
`;