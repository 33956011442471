import { responseStatus, useHandleRequest } from "../api/api-request.service";
import { FinalizeInterface } from "../models/finalize.interface";
import { PaymentInterface } from "../models/payment.interface";
import { ScheduleDataType, ScheduleGroupDateDataType, ScheduleInterface } from "../models/schedule.interface";
import { getDefaultQuery } from "../utils/query-client";

export const scheduleQueries = {
    EDIT: "SCHEDULE_EDIT",
    CREATE: "SCHEDULE_CREATE",
    DELETE: "SCHEDULE_DELETE",
    GET_ALL : "SCHEDULE_GET_ALL",
    GET_PENDING : "SCHEDULE_GET_PENDING",
    GET_ALL_DUE_TODAY : "SCHEDULE_GET_ALL_DUE_TODAY",
    GET_ALL_GROUP_DATE : "SCHEDULE_GET_ALL_GROUP_DATE",
    GET_BY_ID : "SCHEDULE_GET_BY_ID",
    GET_BY_PATIENT_ID : "SCHEDULE_GET_BY_PATIENT_ID",
    GET_ALL_BY_PATIENT_ID : "SCHEDULE_GET_ALL_BY_PATIENT_ID",
}

interface PaymentScheduleProps{
    payload: PaymentInterface,
    onSuccess: Function
}

interface FinalizeScheduleProps{
    payload: FinalizeInterface,
    onSuccess: Function
}

interface GetAllScheduleProps{
    search: string;
    page: number;
    startDate?: string;
    endDate?: string;
}

interface GetAllScheduleGroupDateProps{
    search: string;
    startDate: string;
    endDate: string;
}

interface GetAllSchedulePatientIdProps{
    patientId: number;
    scheduleStatus?: string
}

interface GetOrDeleteScheduleByIdProps{
    id : number;
    onSuccess : Function;
}

interface CreateOrEditScheduleProps{
    payload : ScheduleInterface;
    onSuccess : Function;
}

export function useScheduleService(){

    const handleRequest = useHandleRequest();

    async function createSchedule({payload, onSuccess} : CreateOrEditScheduleProps){
        const {status, data} = await handleRequest.handleRequest(`/schedule/`, 'POST', payload);
        if(status === responseStatus.SUCCESS){
            onSuccess(data);
        } 
    }

    async function editSchedule({payload, onSuccess} : CreateOrEditScheduleProps){
        const {status, data} = await handleRequest.handleRequest(`/schedule/${payload.id}`, 'PUT', payload);
        if(status === responseStatus.SUCCESS){
            onSuccess(data);
        } 
    }

    async function deleteSchedule({id, onSuccess} : GetOrDeleteScheduleByIdProps){
        const {status, data} = await handleRequest.handleRequest(`/schedule/${id}`, 'DELETE');
        if(status === responseStatus.SUCCESS){
            onSuccess(data);
        } 
    }

    async function getScheduleGroupDate({search, startDate, endDate} : GetAllScheduleGroupDateProps) : Promise<ScheduleGroupDateDataType>{
        const filters = [{ search }, { startDate }, { endDate }];
        let query = getDefaultQuery({ page: 1, filters });
        const {status, data} = await handleRequest.handleRequest(`/schedule/groupByDate/${query}`, 'GET');
        if(status === responseStatus.SUCCESS){
            return data;
        } 
    }

    async function getSchedulePatientId({patientId, scheduleStatus} : GetAllSchedulePatientIdProps) : Promise<ScheduleDataType>{
        const filters = [{ patientId }, {status : scheduleStatus}];
        let query = getDefaultQuery({ filters });
        const {status, data} = await handleRequest.handleRequest(`/schedule/by/patient/${query}`, 'GET');
        if(status === responseStatus.SUCCESS){
            return data;
        } 
    }

    async function getAllSchedule({search, startDate, endDate,  page} : GetAllScheduleProps) : Promise<ScheduleDataType>{
        const filters = [{ search }, { startDate }, { endDate }];
        let query = getDefaultQuery({ page, filters });
        const {status, data} = await handleRequest.handleRequest(`/schedule/${query}`, 'GET');
        if(status === responseStatus.SUCCESS){
            return data;
        } 
    }

    async function getPendingSchedule({search, page} : GetAllScheduleProps) : Promise<ScheduleDataType>{
        const filters = [{ search }];
        let query = getDefaultQuery({ page, filters });
        const {status, data} = await handleRequest.handleRequest(`/schedule/pending/${query}`, 'GET');
        if(status === responseStatus.SUCCESS){
            return data;
        } 
    }

    async function getDueTodaySchedule() : Promise<ScheduleDataType>{
        const {status, data} = await handleRequest.handleRequest(`/schedule/due/today`, 'GET');
        if(status === responseStatus.SUCCESS){
            return data;
        } 
    }

    async function getScheduleById({id} : GetOrDeleteScheduleByIdProps) : Promise<ScheduleInterface | undefined>{
        const {status, data} = await handleRequest.handleRequest(`/schedule/${id}`, 'GET');
        if(status === responseStatus.SUCCESS){
            return data;
        } 
    }

    async function payment({payload, onSuccess} : PaymentScheduleProps){
        const {status, data} = await handleRequest.handleRequest(`/schedule/payment/${payload.fkScheduleId}`, 'PUT', payload);
        if(status === responseStatus.SUCCESS){
            onSuccess(data);
        } 
    }

    async function finalize({payload, onSuccess} : FinalizeScheduleProps){
        const {status, data} = await handleRequest.handleRequest(`/schedule/finalize/${payload.fkScheduleId}`, 'PUT', payload);
        if(status === responseStatus.SUCCESS){
            onSuccess(data);
        } 
    }

    return{  
        createSchedule,
        editSchedule,
        deleteSchedule,
        getAllSchedule,
        getScheduleById,
        getScheduleGroupDate,
        getSchedulePatientId,
        payment,
        finalize,
        getDueTodaySchedule,
        getPendingSchedule
    }
}
