import styled from "styled-components";

interface LoadingContainerProps{
    "data-height" : number;
}
export const LoadingContainer = styled.div<LoadingContainerProps>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${props=>props["data-height"]}px;
`;