import { PaginationComponent } from '../pagination';
import * as S from './styled';
import { TableType } from './type';

export const TableComponent = (props : TableType)=>{
    return(
        <S.TableContainer>
            <S.TableContent className={props.className}>
                <table>
                    {props.children}
                </table>
            </S.TableContent>
            <S.TablePaginationContianer>
                <PaginationComponent {...props}/>
            </S.TablePaginationContianer>
        </S.TableContainer>
    )
}