import * as S from './styled';
import { LoadingProps } from "./type";
import { lineSpinner } from 'ldrs'

export const LoadingComponent = ({className, loadingSize, containerHeight}: LoadingProps) => {
    lineSpinner.register()
    return (
        <S.LoadingContainer data-height={containerHeight} className={className}>
            <l-line-spinner
                size={loadingSize || 40}
                stroke="3"
                speed="1" 
                color="#FF4514" 
            ></l-line-spinner>
        </S.LoadingContainer>
    );
};