import { CardBirthdayPersonHookType } from "./type";

export function CardBirthdayPersonHook(props: CardBirthdayPersonHookType) {
    const today = new Date();
    const birthDate = new Date(props.patient.birthDate);
    const isDue = birthDate.getDay() > today.getDay() && birthDate.getMonth() > today.getMonth();

    console.log(isDue)

    return {
        isDue
    }
}
