import { useState } from "react";
import { scheduleQueries, useScheduleService } from "../../../../services/schedule.service";
import { useQuery } from "react-query";
import { ScheduleDataType, ScheduleInterface } from "../../../../models/schedule.interface";

export function usePendantHook(){
    const scheduleService = useScheduleService();
    const [showReceiveDialog, setShowReceiveDialog] = useState<ScheduleInterface | null>(null);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');

    const {
        data : pendingData, 
        isLoading : isLoadingPendingData,
        isRefetching : isRefetchingPendingData,
        refetch : refetchPendingData
    } = useQuery<ScheduleDataType>(scheduleQueries.GET_PENDING, async ()=> await scheduleService.getPendingSchedule({search, page}));


    return {
        dialogs: {
            showReceiveDialog, 
            setShowReceiveDialog
        },
        schedules: {
            pendingData,
            isLoading: isLoadingPendingData || isRefetchingPendingData,
            page,
            setPage,
            setSearch,
            refetchPendingData
        }
    }
}