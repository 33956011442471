
import { ButtonComponent } from '../button';
import * as S from './styled';
import { DeleteModalType } from './type';

export const DeleteModalComponent = (props : DeleteModalType)=>{
    return(
        <S.DeleteModalContainer>
            <span>Ao excluir um {props.title}, todos os dados serão perdidos. Deseja continuar?</span>
            
            <S.DeleteModalAction>
                <ButtonComponent buttonStyle='primary' onClick={()=>props.onDelete()} disabled={props.isLoading}>
                    <span>
                        {props.isLoading ? 'Excluindo...' : `Excluir ${props.title}`}
                    </span>
                </ButtonComponent>

                <ButtonComponent buttonStyle='secondary' onClick={()=>props.onClose()} disabled={props.isLoading}>
                    <span>Não excluir</span>
                </ButtonComponent>
            </S.DeleteModalAction>
        </S.DeleteModalContainer>
    );
}