export class LocalToken {
    
    static storeToken(crefisToken: string) {
        localStorage.setItem('crefisToken', crefisToken);
    }

    static clearStoredToken() {
        localStorage.removeItem('crefisToken');
    }

    static getStoredToken() {
        return localStorage.getItem('crefisToken');
    }

}