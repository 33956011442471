import styled from "styled-components";

interface CardScheduleContainerProps{
   "data-disabled" : boolean
}

export const CardScheduleContainer = styled.div<CardScheduleContainerProps>`
   width: 270px;
   border: 1px solid var(--grey-color-40);
   border-radius: 8px;
   padding: 10px;
   display: flex;
   flex-direction: column;
   gap: 10px;

   ${props=>props["data-disabled"] ? 
      `
         div{  border: none !important;
         
            input{
               width: 120px !important;
               &::-webkit-calendar-picker-indicator {
                  display: none; 
               }
            }
         }
      ` : ''
   }
`;

export const CardScheduleInput = styled.div`
   display: flex;
   gap: 10px;
   border: 1px solid var(--grey-color-40);
   border-radius: 4px;
   padding: 0 4px;
   font-weight: 600;
   font-size: 14.5px;

   img{
      width: 18px;
   }
   
   label{
      display: flex;
      width: 100%;
      align-items: center;
   }
   
   input{
      width: 100%;
      border: none;
      outline: none;
      padding: 4px 6px;
      font-weight: 600;
      background-color: transparent;
   }

   input[type='date']{
      width: 120px;
   }

`;

export const CardScheduleAction = styled.div`
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   gap: 14px;

`;