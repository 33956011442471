import * as S from './styled';
import { DialogComponent } from '../../../components/dialog';
import { DetailsPatientDialogType } from './type';
import { CardScheduleComponent } from '../../../components/cardSchedule';
import { ButtonComponent } from '../../../components/button';

import add_icon from '../../../../assets/icons/add.svg';
import { TableComponent } from '../../../components/table';
import { useDetailsPatientDialogHook } from './hook';
import { addHours, formatDate } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { LoadingComponent } from '../../../components/loading';
import { formatPrice } from '../../../../utils/validations';
import { SchedulePaymentStatus, ScheduleStatus, ScheduleStatusList } from '../../../../models/schedule.interface';
import { FormScheduleDialog } from '../../schedule/formScheduleDialog';
import { FormReceiveDialog } from '../../../components/formReceiveDialog';

export const DetailsPatientDialog = (props: DetailsPatientDialogType) => {

    const hook = useDetailsPatientDialogHook(props);

    return (
        <>
            <S.DetailsPatientDialogContainer>
                <S.DetailsPatientDialogContent>
                    <S.DetailsPatientDialogInfo>
                        <div className='details-group'>
                            <label>Nome completo do paciente:</label>
                            <span>{props.patient.name}</span>
                        </div>

                        <div className='details-group'>
                            <label>CPF:</label>
                            <span>{props.patient.cpf}</span>
                        </div>

                        <div className='details-group'>
                            <label>Data de Nascimento:</label>
                            <span>{formatDate(addHours(props.patient.birthDate, 3), 'dd/MM/yyyy', { locale: ptBR })}</span>
                        </div>

                        <div className='details-group'>
                            <label>Contato 1:</label>
                            <span>{props.patient.phoneNumber}</span>
                        </div>

                        <div className='details-group'>
                            <label>Contato 2:</label>
                            <span>{props.patient.secondPhoneNumber || 'Não Informado'}</span>
                        </div>

                        <div className='details-group'>
                            <label>Endereço:</label>
                            <span>{`${props.patient.street}, ${props.patient.number || 'S/N'}, ${props.patient.neighborhook}, ${props.patient.city}`}</span>
                        </div>
                    </S.DetailsPatientDialogInfo>

                    <S.DetailsPatientDialogSchedules>
                        <h3>Agendamentos</h3>
                        {hook.schedules.isLoading ?
                            <LoadingComponent containerHeight={100} /> :
                            <>
                                {
                                    hook.schedules.scheduleData?.count === 0 ?
                                        <span className='empty'>Nenhum agendamento para {props.patient.name}</span> :
                                        <div className='list'>
                                            {hook.schedules.scheduleData?.rows.map((schedule) => (
                                                <CardScheduleComponent schedule={schedule} />
                                            ))}
                                        </div>
                                }
                            </>

                        }

                        <ButtonComponent buttonStyle='primary' width={210} height={40} onClick={() => hook.dialogs.setShowDialog(true)}>
                            <img src={add_icon} alt="Icone Adiconar" />
                            <span>Fazer novo agenamento</span>
                        </ButtonComponent>
                    </S.DetailsPatientDialogSchedules>

                    <S.DetailsPatientDialogHistories>
                        <h3>Histórico</h3>
                        {hook.histories.isLoading ?
                            <LoadingComponent containerHeight={300} /> :
                            <TableComponent className='history-table'>
                                <thead>
                                    <tr>
                                        <th>Data</th>
                                        <th>Hora</th>
                                        <th>Valor</th>
                                        <th>Status</th>
                                        <th>Observação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {hook.histories.historyScheduleData?.rows.map((schedule) => (
                                        <tr key={`history-${schedule.id}`}>
                                            <td>{formatDate(schedule.scheduleDate, 'dd/MM/yyyy')}</td>
                                            <td>{formatDate(schedule.scheduleDate, 'HH:mm')}</td>
                                            <td className='d-flex'>
                                                <span className='d-price'>{formatPrice(parseFloat(schedule.price))}</span>
                                                {schedule.paymentStatus === SchedulePaymentStatus.PENDING && schedule.status !== ScheduleStatus.CANCELED &&
                                                    <ButtonComponent buttonStyle='primary' width={90} height={20} onClick={()=>hook.dialogs.setScheduleReceiveDialog(schedule)}>
                                                        <span>Receber</span>
                                                    </ButtonComponent>
                                                }
                                            </td>
                                            <td>{ScheduleStatusList[schedule.status]}</td>
                                            <td className='observation'>
                                                {schedule.observation}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </TableComponent>
                        }
                    </S.DetailsPatientDialogHistories>
                </S.DetailsPatientDialogContent>
            </S.DetailsPatientDialogContainer>

            <FormScheduleDialog 
                fkPatientId={props.patient.id}
                onClose={()=>hook.dialogs.setShowDialog(false)}
                isOpen={hook.dialogs.showDialog}
            />

            <FormReceiveDialog 
                schedule={hook.dialogs.scheduleReceiveDialog}
                isOpen={hook.dialogs.scheduleReceiveDialog !== null}
                onClose={()=>hook.dialogs.setScheduleReceiveDialog(null)}
            />
        </>
    );
};