
import { ButtonComponent } from '../button';
import * as S from './styled';
import { CancelModalType } from './type';

export const CancelModalComponent = (props : CancelModalType)=>{
    return(
        <S.CancelModalContainer>
            <span>Você está prestes a cancelar um(a) {props.title}. Deseja continuar?</span>
            
            <S.CancelModalAction>
                <ButtonComponent buttonStyle='primary' onClick={()=>props.onCancel()} disabled={props.isLoading}>
                    <span>
                        {props.isLoading ? 'Cancelando...' : `Cancelar ${props.title}`}
                    </span>
                </ButtonComponent>

                <ButtonComponent buttonStyle='secondary' onClick={()=>props.onClose()} disabled={props.isLoading}>
                    <span>Não cancelar</span>
                </ButtonComponent>
            </S.CancelModalAction>
        </S.CancelModalContainer>
    );
}