import { responseStatus, useHandleRequest } from "../api/api-request.service";
import { UserDataType, UserInterface } from "../models/user.interface";
import { FETCH_LIMIT_TABLE, getDefaultQuery, getOffset } from "../utils/query-client";

export const userQueries = {
    EDIT: "USER_EDIT",
    CREATE: "USER_CREATE",
    DELETE: "USER_DELETE",
    GET_ALL : "USER_GET_ALL",
    GET_BY_ID : "USER_GET_BY_ID",
}

interface GetAllUserProps{
    search: string;
    page: number;
}

interface GetOrDeleteUserByIdProps{
    id : number;
    onSuccess : Function;
}

interface CreateOrEditUserProps{
    payload : UserInterface;
    onSuccess : Function;
}

export function useUserService(){

    const handleRequest = useHandleRequest();

    async function createUser({payload, onSuccess} : CreateOrEditUserProps){
        const {status, data} = await handleRequest.handleRequest(`/user/`, 'POST', payload);
        if(status === responseStatus.SUCCESS){
            onSuccess(data);
        } 
    }

    async function editUser({payload, onSuccess} : CreateOrEditUserProps){
        const {status, data} = await handleRequest.handleRequest(`/user/${payload.id}`, 'PUT', payload);
        if(status === responseStatus.SUCCESS){
            onSuccess(data);
        } 
    }

    async function deleteUser({id, onSuccess} : GetOrDeleteUserByIdProps){
        const {status, data} = await handleRequest.handleRequest(`/user/${id}`, 'DELETE');
        if(status === responseStatus.SUCCESS){
            onSuccess(data);
        } 
    }

    async function getAllUser({search, page} : GetAllUserProps) : Promise<UserDataType>{
        const filters = [{ search }];
        let query = getDefaultQuery({ page, filters });
        const {status, data} = await handleRequest.handleRequest(`/user/${query}`, 'GET');
        if(status === responseStatus.SUCCESS){
            return data;
        } 
    }

    async function getUserById({id} : GetOrDeleteUserByIdProps) : Promise<UserInterface | undefined>{
        const {status, data} = await handleRequest.handleRequest(`/user/${id}`, 'GET');
        if(status === responseStatus.SUCCESS){
            return data;
        } 
    }

    return{  
        createUser,
        editUser,
        deleteUser,
        getAllUser,
        getUserById,
    }
}
