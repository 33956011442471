import { ButtonComponent } from '../button';
import { DialogComponent } from '../dialog';
import { useExitModalHook } from './hook';
import * as S from './styled';
import { ExitModalType } from './type';

export const ExitModal = (props: ExitModalType) => {
    const exitModalHook = useExitModalHook();
    return (
        <DialogComponent
            title='Sair'
            isOpen={props.isOpen}
            onClose={props.onClose}
            width={380}
        >
            <S.ExitModalContainer>
                <span className='b2-regular'>Tem certeza que deseja sair do Sistema Reconhecer?</span>

                <S.ExitModalActions>
                    <ButtonComponent buttonStyle='primary' onClick={exitModalHook.handleExit} disabled={exitModalHook.isLoading}>
                        <span>{exitModalHook.isLoading ? 'Saindo...' : 'Sair'}</span>
                    </ButtonComponent>
                    <ButtonComponent buttonStyle='secondary' onClick={props.onClose} disabled={exitModalHook.isLoading}>
                        <span>Voltar</span>
                    </ButtonComponent>
                </S.ExitModalActions>
            </S.ExitModalContainer>
        </DialogComponent>
    )
}