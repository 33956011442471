import * as S from './styled';

import { ButtonComponent } from '../../../components/button';
import { InputSearchComponent } from '../../../components/inputSearch';
import { TableComponent } from '../../../components/table';
import { useState } from 'react';
import { FormPatientDialog } from '../formPatientDialog';
import { DeleteModalComponent } from '../../../components/deleteModal';
import { DetailsPatientDialog } from '../detailsPatientDialog';

import add_icon from '../../../../assets/icons/add.svg';
import delete_icon from '../../../../assets/icons/delete.svg';
import edit_icon from '../../../../assets/icons/edit.svg';
import details_icon from '../../../../assets/icons/details.svg';
import { usePatientHook } from './hook';
import { DialogComponent } from '../../../components/dialog';
import { EmptyTableComponent } from '../../../components/emptyTable';
import { addHours, formatDate } from 'date-fns';
import { enUS, ptBR } from 'date-fns/locale';

export const PatientPage = () => {

    const hook = usePatientHook();

    return (
        <>
            <S.PatientContainer>
                <span className='b1-bold'>Lista de Pacientes</span>

                <S.PatientSearchBar>
                    <InputSearchComponent placeholder='Buscar pelo nome' setSearch={hook.patient.setSearch} refetch={hook.patient.handleRefetchData}/>
                    <ButtonComponent buttonStyle='primary' width={150} onClick={() => hook.dialogs.setShowFormDialog(true)}>
                        <img src={add_icon} alt="Icone de adicionar" />
                        <span className='b3-regular'>Novo Paciente</span>
                    </ButtonComponent>
                </S.PatientSearchBar>

                <S.PatientTableSection>
                    <TableComponent
                        setPage={hook.patient.setPage}
                        page={hook.patient.page}
                        totalItems={hook.patient.patientData?.count}
                    >
                        <thead className='b3-bold'>
                            <tr>
                                <th>Nome</th>
                                <th>CPF</th>
                                <th>Endereço</th>
                                <th>Contato</th>
                                <th>Data de Nascimento</th>
                                <th></th>
                            </tr>
                        </thead>
                        {hook.patient.isLoading || hook.patient.patientData?.count === 0 ?
                            <EmptyTableComponent colSpan={6} isEmpty={hook.patient.patientData?.count === 0} isLoading={hook.patient.isLoading} /> :
                            <tbody className='b3-regular'>
                                {hook.patient.patientData?.rows?.map((patient) => (
                                    <tr>
                                        <td>{patient.name}</td>
                                        <td>{patient.cpf}</td>
                                        <td>{`${patient.street}, ${patient.number}, ${patient.neighborhook}, ${patient.city}`}</td>
                                        <td>{patient.phoneNumber}</td>
                                        <td>{patient.birthDate && formatDate(addHours(patient.birthDate, 3), 'dd/MM/yyyy', {locale: ptBR})}</td>
                                        <td className='d-flex actions'>
                                            <img src={details_icon} alt="Icone de detalhes" onClick={() => hook.dialogs.handleOpenDetailsModal(patient)} />
                                            <img src={edit_icon} alt="Icone de editar" onClick={() => hook.dialogs.handleOpenFormModal(patient)} />
                                            <img src={delete_icon} alt="Icone de apagar" onClick={() => hook.dialogs.handleOpenDeleteModal(patient)} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        }
                    </TableComponent>
                </S.PatientTableSection>
            </S.PatientContainer>


            <DialogComponent
                width={570}
                height={540}
                title='Novo paciente'
                onClose={hook.dialogs.handleCloseFormModal}
                isOpen={hook.dialogs.showFormDialog}
            >
                <FormPatientDialog patient={hook.patient.selectedPatient} onClose={hook.dialogs.handleCloseFormModal} />
            </DialogComponent>



            <DialogComponent
                width={386}
                title={`Excluir Paciente`}
                onClose={hook.dialogs.handleCloseDeleteModal}
                isOpen={hook.dialogs.showDeleteDialog}
            >
                <DeleteModalComponent
                    title='Paciente'
                    onClose={hook.dialogs.handleCloseDeleteModal}
                    onDelete={hook.patient.handleDeletePatient}
                    isLoading={hook.patient.isLoadingDelete}
                />
            </DialogComponent>


            <DialogComponent
                onClose={hook.dialogs.handleCloseDetailsModal}
                isOpen={hook.dialogs.showDetailsDialog}
                width={1050}
                height={1000}
                title='Detalhes do paciente'
            >
                <DetailsPatientDialog patient={hook.patient.selectedPatient!}/>
            </DialogComponent>
        </>
    )
}