import { useQuery } from "react-query";
import { useAppSelector } from "../../../redux/hooks/hooks";
import { UserInterface } from "../../../models/user.interface";
import { useUserService, userQueries } from "../../../services/user.service";

export function useTopBarHook(){
    const user = useAppSelector((state) => state.user);
    const sidebarItem = useAppSelector(state => state.general.sideBarItem);
    const userName = user.authUser?.name.split(' ');

    return { 
        sidebarItem,
        user,
        userName
    }
}