import styled from "styled-components";

export const ScheduleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const ScheduleSearchBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
`;

export const ScheduleSearchBarInputs = styled.div`
    display: flex;
    gap: 16px;
    height: 42px;

    input[type="month"]{
        padding: 0 12px;
        border: none;
        border-radius: 8px;
        background-color: var(--background-color);
        outline: none;
    }
`;

export const ScheduleTableSection = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    .content{
        border-bottom: 1px solid var(--card-border-color);
        .add-button{
            margin-bottom: 16px;
        }
    }

    .hidden {
        max-height: 0;
        overflow: hidden;
        transition: max-height 1s;
    }

    .show {
        max-height: 550px;
        overflow: scroll;
        transition: max-height 2s;
    }


`;

export const ScheduleList = styled.div`
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin: 12px 0;
`;

export const ScheduleDay = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
    cursor: pointer;
    

    .actions{
        display: flex;
        gap: 20px;

        span{
            display: flex;
            align-items: center;
            justify-content: center;
            
            height: 25px;
            width: 25px;
            background-color: var(--orange-color-100);
            color: var(--white-color);

            border-radius: 4px;
        }
    }
    
`;