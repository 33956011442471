import styled from "styled-components";

export const DetailsPatientDialogContainer = styled.div`

`;

export const DetailsPatientDialogContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

export const DetailsPatientDialogInfo = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px 50px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--grey-color-40);

    .details-group{
        display: flex;
        flex-direction: column;
        gap: 5px;

        label{
            font-size: 14px;
            color: var(--grey-color-40);
        }
    }
`;

export const DetailsPatientDialogSchedules = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    .empty{
        height: 100px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .list{
        display: flex;
        gap: 16px 32px;
        flex-wrap: wrap;
    }
`;

export const DetailsPatientDialogHistories = styled.div`
    .d-price{
        width: 90px;
    }
    
    .history-table{
        width: 100% !important;
        max-height: none !important;
    }

    .observation{
        width: 300px;
    }
`;