import * as S from './styled';
import { useState } from 'react';
import { ButtonComponent } from '../../../components/button';
import { InputSearchComponent } from '../../../components/inputSearch';
import { TableComponent } from '../../../components/table';
import { FormReceiveDialog } from '../../../components/formReceiveDialog';
import { usePendantHook } from './hook';
import { formatDate } from 'date-fns';
import { formatPrice } from '../../../../utils/validations';
import { EmptyTableComponent } from '../../../components/emptyTable';

export const PendantPage = () => {

    const hook = usePendantHook();

    return (
        <>
            <S.PendantContainer>
                <span className='b1-bold'>Lista de Pagamentos Pendentes</span>

                <S.PendantSearchBar>
                    <InputSearchComponent placeholder='Buscar pelo nome' setSearch={hook.schedules.setSearch} refetch={hook.schedules.refetchPendingData}/>
                </S.PendantSearchBar>

                <S.PendantTableSection>
                    <TableComponent
                        setPage={hook.schedules.setPage}
                        page={hook.schedules.page}
                        totalItems={hook.schedules.pendingData?.count}
                    >
                        <thead className='b3-bold'>
                            <tr>
                                <th>Nome</th>
                                <th>CPF</th>
                                <th>Data do Agendamento</th>
                                <th>Data do Atendimento</th>
                                <th>Valor</th>
                                <th></th>
                            </tr>
                        </thead>

                        {hook.schedules.isLoading || hook.schedules.pendingData?.count === 0 ?
                            <EmptyTableComponent colSpan={6} isEmpty={hook.schedules.pendingData?.count === 0} isLoading={hook.schedules.isLoading} /> :
                            <tbody className='b3-regular'>
                                {hook.schedules.pendingData?.rows.map((pending) => (
                                    <tr>
                                        <td>{pending.patient?.name}</td>
                                        <td>{pending.patient?.cpf}</td>
                                        <td>{formatDate(pending.scheduleDate!, 'dd/MM/yyyy')}</td>
                                        <td>{pending.serviceDate ? formatDate(pending.serviceDate, 'dd/MM/yyyy') : ''}</td>
                                        <td>{formatPrice(pending.price)}</td>
                                        <td className='d-flex'>
                                            <ButtonComponent
                                                buttonStyle='primary'
                                                height={30}
                                                width={100}
                                                onClick={() => hook.dialogs.setShowReceiveDialog(pending)}
                                            >
                                                <span>Receber</span>
                                            </ButtonComponent>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        }
                    </TableComponent>
                </S.PendantTableSection>
            </S.PendantContainer>


            <FormReceiveDialog
                schedule={hook.dialogs.showReceiveDialog}
                isOpen={hook.dialogs.showReceiveDialog !== null}
                onClose={() => hook.dialogs.setShowReceiveDialog(null)}
            />

        </>
    )
}