import styled from "styled-components";

export const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 30px;
    

    .form-row{
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }

    .form-group{
        display: flex;
        flex-direction: column;
        gap: 8px;

        :disabled{
            background-color: var(--grey-color-20);
        }

        label{
            color: var(--grey-color-60);
        }

        input, select, textarea{
            display: flex;
            width: 100%;
            height: 32px;
            padding-left: 8px;
            align-items: center;
            gap: 10px;

            border-radius: 4px;
            border: 1px solid var(--input-border-color);
            background: var(--card-bg-color);
        }

        textarea{
            height: 100px;
            resize: none;
            padding: 5px;
        }

    }

    .error{
        font-size: 10px;
        margin-top: -5px;
        color: var(--orange-color-100);
    }

    .middle{
        width: 50%;
    }

    .small{
        width: 35%;
    }

    .long{
        width: 65%;
    }
`;