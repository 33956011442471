import { formatDate, getDaysInMonth, lastDayOfMonth } from "date-fns";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { scheduleQueries, useScheduleService } from "../../../../services/schedule.service";
import { userSchemaEdit } from "../../../../models/schemas/user.schema";
import { ScheduleGroupDateDataType } from "../../../../models/schedule.interface";

export function useScheduleHook(){
    const scheduleService = useScheduleService();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedFormDate, setSelectedFormDate] = useState<Date>();
    const [search, setSearch] = useState('');
    const [selectedDay, setSelectedDay] = useState<number>();
    const [showFormDialog, setShowFormDialog] = useState(false);
    
    const daysInMonth = getDaysInMonth(selectedDate);
    const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);

    const {
        data: scheduleData,
        isLoading: isLoadingScheduleData,
        isRefetching: isRefetchingScheduleData,
        refetch: refetchScheduleData
    } = useQuery<ScheduleGroupDateDataType>(scheduleQueries.GET_ALL_GROUP_DATE, async () => await scheduleService.getScheduleGroupDate({ 
        search, 
        startDate: formatDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1), 'yyyy-MM-dd'), 
        endDate : formatDate(lastDayOfMonth(selectedDate), 'yyyy-MM-dd')
    }));

    function handleSelectDay(day : number){
        setSelectedDay(selectedDay === day ? undefined : day);
        setSelectedFormDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day))
    }

    function handleOpenDialog(){
        setShowFormDialog(true);
        setSelectedFormDate(undefined)
    }

    useEffect(()=>{
        refetchScheduleData();
    }, [selectedDate]);

    return{
        general : {
            selectedDate,
            selectedDay, 
            daysArray,
            selectedFormDate,
            setSelectedDay,
            setSelectedDate,
            setSelectedFormDate,
            handleSelectDay
        },
        dialogs:{
            showFormDialog,
            setShowFormDialog,
            handleOpenDialog
        },
        schedules: {
            scheduleData,
            isLoading: isLoadingScheduleData || isRefetchingScheduleData,
            refetchScheduleData,
        }
    }
}