import * as S from './styled';

import { useSidebarHook } from './hook';

import logo_image from '../../../assets/images/logo.png';
import logout_icon from '../../../assets/icons/logout.svg';
import { ExitModal } from '../exitModal';

export const SidebarComponent = ()=>{
    const hook = useSidebarHook();

    return(
        <S.SidebarContainer className='b2-regular'>
            <S.SidebarHeader>
                <S.SidebarLogo>
                    <img src={logo_image} alt="Logo Crefis"/>
                </S.SidebarLogo>
                
                <S.SidebarItems>
                    { hook.sidebarItems.map((sidebarItem, index)=>(
                        <S.SidebarItem 
                            key={sidebarItem.name}
                            selected={hook.path === sidebarItem.link}
                            onMouseEnter={()=>hook.handleMouseEnter(index)}
                            onMouseLeave={()=>hook.handleMouseLeave()}
                            onClick={()=>hook.navigate(sidebarItem.link)}
                        > 
                            <img src={sidebarItem.activeIcon} alt={"Icone do sidebar"} className={hook.hoveredItem === index || hook.path === sidebarItem.link ? '' : 'd-none'}/>
                            <img src={sidebarItem.icon} alt={"Icone do sidebar"} className={hook.hoveredItem === index || hook.path === sidebarItem.link ? 'd-none' : ''}/>
                            <span>{sidebarItem.name}</span>
                        </S.SidebarItem>
                    ))}                
                </S.SidebarItems>
            </S.SidebarHeader>


            <S.SidebarFoot>

                <S.SidebarItem onClick={()=>hook.setIsOpenModal(true)}>
                    <img src={logout_icon} alt={"Icone do sidebar"}/>
                    <span>Sair</span>
                </S.SidebarItem>

            </S.SidebarFoot>

            <ExitModal isOpen={hook.isOpenModal} onClose={()=>hook.setIsOpenModal(false)}/>

        </S.SidebarContainer>
    );
}