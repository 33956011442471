import * as S from './styled';

import { ButtonComponent } from '../button';
import { InputCurrencyComponent } from '../inputCurrency';

import calendar_icon from '../../../assets/icons/calendar.svg';
import clock_icon from '../../../assets/icons/clock.svg';
import money_icon from '../../../assets/icons/money.svg';
import { CardScheduleType } from './type';
import { useCardScheduleHook } from './hook';
import { formatDate } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { DialogComponent } from '../dialog';
import { CancelModalComponent } from '../cancelModal';
import { FormReceiveDialog } from '../formReceiveDialog';
import { FormScheduleDialog } from '../../modules/schedule/formScheduleDialog';
import { SchedulePaymentStatus } from '../../../models/schedule.interface';

import profile_icon from '../../../assets/icons/profile.svg';
import { FormFinalizeDialog } from '../formFinalizeDialog';

export const CardScheduleComponent = (props: CardScheduleType) => {

    const hook = useCardScheduleHook(props);

    return (
        <>
            <form onSubmit={hook.form.scheduleForm.handleSubmit(hook.form.onSubmit)}>
                <S.CardScheduleContainer data-disabled={false}>

                    {props.today &&
                        <S.CardScheduleInput>
                            <img src={profile_icon} alt='Icone de perfil' />
                            <input type='text' disabled value={props.schedule.patient?.name} />
                        </S.CardScheduleInput>
                    }

                    <S.CardScheduleInput>
                        <img src={calendar_icon} alt='Icone Calendário' />
                        <label htmlFor='scheduleDate'>
                            <input id='scheduleDate' type='date' {...hook.form.scheduleForm.register('scheduleDate')} />
                            <span>{formatDate(props.schedule.scheduleDate, 'EEEE', { locale: ptBR })}</span>
                        </label>
                    </S.CardScheduleInput>

                    <S.CardScheduleInput>
                        <img src={clock_icon} alt='Icone Relógio' />
                        <input type='time' {...hook.form.scheduleForm.register('hour')} />
                    </S.CardScheduleInput>

                    <S.CardScheduleInput>
                        <img src={money_icon} alt='Icone Dinheiro' />
                        <InputCurrencyComponent id="price" value={parseFloat(props.schedule.price)} register={hook.form.scheduleForm.register} />
                    </S.CardScheduleInput>

                    <S.CardScheduleAction>
                        <ButtonComponent type='submit' buttonStyle='primary' height={24} disabled={hook.form.isLoadingSubmit || props.schedule.status === "CONCLUDED"}>
                            <span>{hook.form.isLoadingSubmit ? 'Salvando...' : 'Reagendar'}</span>
                        </ButtonComponent>


                        {(props.schedule.paymentStatus !== SchedulePaymentStatus['PAID OUT'] && props.schedule.status !== "CONCLUDED") &&
                            <ButtonComponent buttonStyle='terciary' height={24} onClick={() => hook.form.setSelectCancelDialog(props.schedule)} disabled={hook.form.isLoadingSubmit}>
                                <span>Cancelar</span>
                            </ButtonComponent>
                        }

                    </S.CardScheduleAction>

                    <ButtonComponent
                        buttonStyle='secondary'
                        height={24}
                        onClick={
                            (props.today && props.schedule.status !== "CONCLUDED") ?
                                () => hook.form.setSelectFinalizeDialog(props.schedule) :
                                props.schedule.paymentStatus === "PENDING" ?
                                    () => hook.form.setSelectReceiveDialog(props.schedule) :
                                    () => hook.form.setDetailsDialog(props.schedule)
                        }
                        disabled={hook.form.isLoadingSubmit}
                    >
                        <span>{
                            (props.today && props.schedule.status !== "CONCLUDED") ?
                                "Concluir Atendimento" :
                                props.schedule.paymentStatus === "PENDING" ?
                                    "Receber pagamento" :
                                    props.schedule.status === "CONCLUDED" ? 
                                    "Ver detalhes" :
                                    "Editar agendamento"
                        }</span>
                    </ButtonComponent>

                </S.CardScheduleContainer>
            </form>

            <DialogComponent
                width={450}
                title={`Cancelar Agendamento`}
                onClose={() => hook.form.setSelectCancelDialog(null)}
                isOpen={hook.form.selectCancelDialog !== null}
            >
                <CancelModalComponent
                    title='Agendamento'
                    onClose={() => hook.form.setSelectCancelDialog(null)}
                    onCancel={hook.form.handleCancelSchedule}
                    isLoading={hook.form.isLoadingSubmit}
                />
            </DialogComponent>

            <FormReceiveDialog
                schedule={props.schedule}
                isOpen={hook.form.selectReceiveDialog !== null}
                onClose={() => hook.form.setSelectReceiveDialog(null)}

            />

            <FormFinalizeDialog
                schedule={props.schedule}
                isOpen={hook.form.selectFinalizeDialog !== null}
                onClose={() => hook.form.setSelectFinalizeDialog(null)}

            />

            <FormScheduleDialog
                isDetails={true}
                schedule={props.schedule}
                onClose={() => hook.form.setDetailsDialog(null)}
                isOpen={hook.form.detailsDialog !== null}
                showFinalize={true}
                
            />
        </>
    );
}