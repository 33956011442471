import { useMutation, useQuery } from "react-query";
import { scheduleQueries, useScheduleService } from "../../../../services/schedule.service";
import { ScheduleSchemaType, scheduleSchema } from "../../../../models/schemas/schedule.schema";
import { FormScheduleDialogHookType } from "./type";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ScheduleInterface } from "../../../../models/schedule.interface";
import { queryClient } from "../../../../utils/query-client";
import { formatPrice } from "../../../../utils/validations";
import { PatientDataType } from "../../../../models/patient.interface";
import { patientQueries, usePatientService } from "../../../../services/patient.service";
import { useEffect, useState } from "react";
import { SingleValue } from "react-select";

export function useFormScheduleDialogHook(props: FormScheduleDialogHookType) {
    const scheduleService = useScheduleService();
    const patientService = usePatientService();
    const [search, setSearch] = useState('');
    const [searchTerm, setSearchTerm] = useState('');


    const { mutate, isLoading: isLoadingSubmit } = useMutation(
        async (payload: ScheduleSchemaType) =>
            props.schedule?.id ?
                await scheduleService.editSchedule({ payload: payload as ScheduleInterface, onSuccess }) :
                await scheduleService.createSchedule({ payload: payload as ScheduleInterface, onSuccess })
    );

    const scheduleForm = useForm<ScheduleSchemaType>({
        resolver: zodResolver(scheduleSchema),
        defaultValues: {
            ...props.schedule,
            scheduleDate: props.schedule?.scheduleDate ? new Date(props.schedule.scheduleDate).toISOString().slice(0, 10) : props.selectedDate ? props.selectedDate.toISOString().slice(0, 10) : '',
            hour: props.schedule?.scheduleDate ? new Date(props.schedule.scheduleDate).toISOString().slice(11, 16) : '',
            price: formatPrice(parseFloat(props.schedule?.price.toString() || '0'))
        } as ScheduleSchemaType
    });

    function onSubmit(data: ScheduleSchemaType) {
        const { scheduleDate, hour } = data;
        const formattedDate = new Date(`${scheduleDate}T${hour}:00.000Z`);
        mutate({
            ...data,
            scheduleDate: formattedDate.toISOString()
        } as ScheduleInterface);
    }

    function onSuccess() {
        queryClient.refetchQueries(scheduleQueries.GET_ALL);
        queryClient.refetchQueries(scheduleQueries.GET_ALL_BY_PATIENT_ID);
        queryClient.refetchQueries(scheduleQueries.GET_BY_PATIENT_ID);
        queryClient.refetchQueries(scheduleQueries.GET_ALL_GROUP_DATE);
        handleClose();
    }

    const {
        data: patientData,
        isLoading: isLoadingPatientData,
        isRefetching: isRefetchingPatientData,
        refetch: refetchPatientData
    } = useQuery<{ value: string, label: string }[] | undefined>(patientQueries.GET_ALL_OPTIONS, async () => await patientService.getPatientOptions({ search, page: 1 }));


    function handleSearch(newValue: string) {
        setSearchTerm(newValue);
    }

    function handleChangePatient(option: SingleValue<{ value: string; label: string; }>) {
        scheduleForm.setValue('fkPatientId', parseInt(option!.value));
        scheduleForm.setError('fkPatientId', {message: ''});
    }

    function handleClose(){
        scheduleForm.reset();
        props.onClose();
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setSearch(searchTerm);
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    return {
        form: {
            scheduleForm,
            isLoadingSubmit,
            onSubmit,
            handleChangePatient,
            handleClose
        },

        patient: {
            patientData,
            isLoading: isLoadingPatientData || isRefetchingPatientData,
            refetchPatientData,
            handleSearch
        }

    }
}