import * as S from './styled';

import profile_icon from '../../../assets/icons/profile.svg';
import { CardBirthdayPersonType } from './type';
import { formatDate, addHours } from 'date-fns';
import { CardBirthdayPersonHook } from './hook';

export const CardBirthdayPersonComponent = ( props : CardBirthdayPersonType)=>{

    const hook = CardBirthdayPersonHook(props);

    return(
        <S.CardBirthdayPersonContainer>
            <img src={profile_icon} alt='Icone de usuário'/>
            <span className='b2-bold'>{props.patient.name}</span>
            <S.CardBirthdayPersonInfos>
                <span className='c1-bold'>{formatDate(addHours(props.patient.birthDate, 3), 'dd/MM/yyyy')}</span>
                <span className='c1-bold'>{props.patient.phoneNumber}</span>
            </S.CardBirthdayPersonInfos>
        </S.CardBirthdayPersonContainer>
    );
}