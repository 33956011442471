import { useMutation } from "react-query";
import { UserSchemaType, userSchema, userSchemaEdit } from "../../../../models/schemas/user.schema";
import { useUserService, userQueries } from "../../../../services/user.service";
import { queryClient } from "../../../../utils/query-client";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { UserInterface } from "../../../../models/user.interface";
import { FormUserDialogHookType } from "./type";

export function useFormUserDialogHook(props: FormUserDialogHookType){
    const userService = useUserService();

    const { mutate, isLoading: isLoadingSubmit } = useMutation(
        async (payload: UserSchemaType) => 
            props.user?.id ?
            await userService.editUser({payload, onSuccess}) :
            await userService.createUser({payload, onSuccess})
    );

    const userForm = useForm<UserSchemaType>({
        resolver: zodResolver(props.user?.id ? userSchemaEdit : userSchema),
        defaultValues: {...props.user} as UserSchemaType
    });

    function onSubmit(data : UserSchemaType){
        if(!data.password){
            delete data.password
        }
        mutate({...data, cpf : data.cpf} as UserInterface);
    }

    function onSuccess(){
        queryClient.refetchQueries(userQueries.GET_ALL);
        if(!props.user?.id){
            props.onClose();
        }
    }

    return{
        userForm,
        isLoadingSubmit,
        onSubmit,
    }
}