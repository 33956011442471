import styled from "styled-components";

export const EmptyTableContainer = styled.tr`
    width: 100%;
    height: 200px;
    text-align: center;

    td{
        width: 100%;
        text-align: center;
        
    };

    .empty-text{
            margin-left: calc(50% - 89.5px);
        }
  
`;