import styled from "styled-components";

export const FormFinancialDialogContainer = styled.div`

`;

export const FormFinancialDialogContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    input[type="date"], input[type="time"]{
        padding: 0 10px;
    }

    .time-group{
        max-width: 90px;
    }

    .check-container{
        display: flex;
        gap: 30px;
        
        input{
            width: 16px !important;
            height: 16px !important;
        }

        .check-in, .check-out, .check-in>label, .check-out>label{
            display: flex;
            gap: 10px;
            align-items: center;
            cursor: pointer;
        }

        .check-in>label>span{
            color: var(--green-color) !important;
        }

        .check-out>label>span{
            color: var(--orange-color-100) !important;
        }
    }
`;