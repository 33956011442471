import * as S from './styled';
import { DialogComponent } from '../../../components/dialog';
import { FormComponent } from '../../../components/form';
import { FormFinancialDialogType } from './type';
import { ButtonComponent } from '../../../components/button';
import { InputCurrencyComponent } from '../../../components/inputCurrency';
import { ArrowComponent } from '../../../components/arrow';
import { useFormFinancialDialogHook } from './hook';

export const FormFinancialDialog = (props: FormFinancialDialogType) => {

    const hook = useFormFinancialDialogHook(props);

    return (
        <S.FormFinancialDialogContainer>
            <FormComponent onSubmit={hook.financialForm.handleSubmit(hook.onSubmit)}>
                <S.FormFinancialDialogContent>

                    <div className='form-group'>
                        <label className="c1-regular">Tipo: *</label>
                        <div className='check-container'>
                            <div className='check-in'>
                                <label htmlFor='in'>
                                    <input type='radio' id='in' value='IN' {...hook.financialForm.register('type')} />
                                    <span>Entrada</span>
                                </label>
                                <ArrowComponent direction='up' color='green' />
                            </div>

                            <div className='check-out'>
                                <label htmlFor='out'>
                                    <input type='radio' id='out' value='OUT' {...hook.financialForm.register('type')} />
                                    <span>Saída</span>
                                </label>
                                <ArrowComponent direction='down' color='red' />
                            </div>
                        </div>
                        <span className='error'>{hook.financialForm.formState.errors.type?.message}</span>
                    </div>

                    <div className='form-row'>
                        <div className='form-group'>
                            <label htmlFor="date" className="c1-regular">Data: *</label>
                            <input type='date' id='date' {...hook.financialForm.register('date')} />
                            <span className='error'>{hook.financialForm.formState.errors.date?.message}</span>
                        </div>

                        <div className='form-group time-group'>
                            <label htmlFor="name" className="c1-regular">Hora:</label>
                            <input type='time' id='time' {...hook.financialForm.register('hour')} />
                            <span className='error'>{hook.financialForm.formState.errors.hour?.message}</span>
                        </div>
                    </div>

                    <div className='form-group'>
                        <label htmlFor="value" className="c1-regular">Valor: *</label>
                        <InputCurrencyComponent id='value' register={hook.financialForm.register} />
                        <span className='error'>{hook.financialForm.formState.errors.value?.message}</span>
                    </div>

                    <div className='form-group'>
                        <label htmlFor="observation" className="c1-regular">Observação: *</label>
                        <input type='text' id='observation' {...hook.financialForm.register('observation')} />
                        <span className='error'>{hook.financialForm.formState.errors.observation?.message}</span>
                    </div>

                </S.FormFinancialDialogContent>

                <ButtonComponent buttonStyle='primary' type='submit' disabled={hook.isLoadingSubmit}>
                    <span className="b3-bold">
                        { hook.isLoadingSubmit ? 'Salvando...' : 'Salvar lançamento' }
                    </span>
                </ButtonComponent>

            </FormComponent>

        </S.FormFinancialDialogContainer>
    );
};