import { useQuery } from "react-query";
import { PatientDataType } from "../../../../models/patient.interface";
import { patientQueries, usePatientService } from "../../../../services/patient.service";
import { scheduleQueries, useScheduleService } from "../../../../services/schedule.service";
import { ScheduleDataType } from "../../../../models/schedule.interface";

export function useOverviewHook(){

    const patientService = usePatientService();
    const scheduleService = useScheduleService();

    const {
        data : birthDayTodayData, 
        isLoading : isLoadingbirthDayTodayData,
        isRefetching : isRefetchingbirthDayTodayData,
        refetch : refetchbirthDayTodayData
    } = useQuery<PatientDataType>(patientQueries.GET_BIRTHDAY_TODAY, async ()=> await patientService.getByrthdayTodayPatient());


    const {
        data : birthDayMonthData, 
        isLoading : isLoadingbirthDayMonthData,
        isRefetching : isRefetchingbirthDayMonthData,
        refetch : refetchbirthDayMonthData
    } = useQuery<PatientDataType>(patientQueries.GET_BIRTHDAY_MONTH, async ()=> await patientService.getByrthdayMonthPatient());


    const {
        data : schedulesData, 
        isLoading : isLoadingScheduleData,
        isRefetching : isRefetchingScheduleData,
        refetch : refetchScheduleData
    } = useQuery<ScheduleDataType>(scheduleQueries.GET_ALL_DUE_TODAY, async ()=> await scheduleService.getDueTodaySchedule());

    function isEmptyData(){
        return birthDayTodayData?.count === 0  && birthDayMonthData?.count === 0 && schedulesData?.count === 0;
    }

    return {
        general: {
            isEmptyData,
            isLoading: 
                isLoadingbirthDayTodayData || isRefetchingbirthDayTodayData || 
                isLoadingbirthDayMonthData || isRefetchingbirthDayMonthData ||
                isLoadingScheduleData || isRefetchingScheduleData,

        },
        birthDayToday : {
            birthDayTodayData,
            refetchbirthDayTodayData
        },
        birthDayMonth : {
            birthDayMonthData,
            refetchbirthDayMonthData
        },
        schedules: {
            schedulesData,
            refetchScheduleData
        }
    }
    
}