
import * as S from './styled';
import close_modal_icon from '../../../assets/icons/close_modal.svg';
import { DialogType } from './type';

export const DialogComponent = ({ width, height, children, title, isOpen, onClose }: DialogType) => {
    return (
        <>
            {isOpen ?
                <S.DialogContainer>
                    <S.DialogContent data-width={width} data-height={height}>
                        <S.DialogTitle>
                            <span className="b1-bold">{title}</span>
                            <img onClick={onClose} src={close_modal_icon} alt="Icone de fechar" />
                        </S.DialogTitle>

                        <S.DialogChildren>
                            {children}
                        </S.DialogChildren>

                    </S.DialogContent>
                </S.DialogContainer> :
                <></>
            }
        </>
    );
}