import * as S from './styled';
import Select from 'react-select';
import { FormComponent } from '../../../components/form';
import { FormScheduleDialogType } from './type';
import { ButtonComponent } from '../../../components/button';
import { useFormScheduleDialogHook } from './hook';
import { InputCurrencyComponent } from '../../../components/inputCurrency';

import search_icon from '../../../../assets/icons/search.svg';
import { DialogComponent } from '../../../components/dialog';
import { SchedulePaymentStatus } from '../../../../models/schedule.interface';

export const FormScheduleDialog = (props: FormScheduleDialogType) => {

    const hook = useFormScheduleDialogHook(props);

    const DropdownIndicator = (props: any) => {
        return (
            <div {...props} style={{ marginRight: '10px' }}>
                <img src={search_icon} alt='Icone de busca' />
            </div>
        );
    };

    return (
        <DialogComponent
            onClose={()=>hook.form.handleClose()}
            width={330}
            title={`${props.schedule?.id ? 'Editar' : 'Novo'} Agendamento`}
            isOpen={props.isOpen}
        >
            <S.FormScheduleDialogContainer>
                <FormComponent onSubmit={hook.form.scheduleForm.handleSubmit(hook.form.onSubmit)}>
                    <S.FormScheduleDialogContent>
                        <div className='form-group'>
                            <label htmlFor="fkPatientId" className="c1-regular">Paciente: *</label>
                            <Select
                                styles={S.customStyles}
                                id='fkPatientId'
                                {...hook.form.scheduleForm.register('fkPatientId')}
                                options={hook.patient.patientData}
                                isSearchable
                                onInputChange={hook.patient.handleSearch}
                                components={{ DropdownIndicator }}
                                onChange={hook.form.handleChangePatient}
                                value={hook.patient.patientData?.find(patient => patient.value === props.schedule?.fkPatientId.toString() || patient.value === props.fkPatientId?.toString())}
                                isDisabled={!!props.schedule}
                            />
                            <span className='error'>{hook.form.scheduleForm.formState.errors.fkPatientId?.message}</span>
                        </div>

                        <div className='form-row'>
                            <div className='form-group'>
                                <label htmlFor="date" className="c1-regular">Data: *</label>
                                <input type='date' id='date' {...hook.form.scheduleForm.register('scheduleDate')} disabled={props.schedule?.status === 'CONCLUDED'}/>
                                <span className='error'>{hook.form.scheduleForm.formState.errors.scheduleDate?.message}</span>
                            </div>

                            <div className='form-group'>
                                <label htmlFor="time" className="c1-regular">Hora:</label>
                                <input type='time' id='time' {...hook.form.scheduleForm.register('hour')} disabled={props.schedule?.status === 'CONCLUDED'}/>
                                <span className='error'>{hook.form.scheduleForm.formState.errors.hour?.message}</span>
                            </div>
                        </div>

                        <div className='form-group'>
                            <label htmlFor="price" className="c1-regular">Valor: </label>
                            <InputCurrencyComponent id='price' value={props.schedule ? parseFloat(props.schedule.price) : 0} register={hook.form.scheduleForm.register} disabled={props.schedule?.paymentStatus === SchedulePaymentStatus['PAID OUT'] || props.schedule?.status === 'CONCLUDED'}/>
                            <span className='error'>{hook.form.scheduleForm.formState.errors.price?.message}</span>
                        </div>

                        <div className='form-group'>
                            <label htmlFor="observation" className="c1-regular">Observação:</label>
                            <textarea disabled={props.schedule?.status === 'CONCLUDED'} id='observation' {...hook.form.scheduleForm.register('observation')}></textarea>
                            <span className='error'>{hook.form.scheduleForm.formState.errors.observation?.message}</span>
                        </div>

                        {props.showFinalize &&
                            <div className='form-group'>
                                <label htmlFor="finalizeObservation" className="c1-regular">Observação Final:</label>
                                <textarea disabled={props.schedule?.status === 'CONCLUDED'} id='finalizeObservation' {...hook.form.scheduleForm.register('finalizeObservation')}></textarea>
                            </div>
                        }

                    </S.FormScheduleDialogContent>

                    <ButtonComponent 
                        buttonStyle='primary' 
                        type={props.schedule?.status === 'CONCLUDED' ? 'button' : 'submit'} 
                        disabled={hook.form.isLoadingSubmit} 
                        onClick={props.schedule?.status === 'CONCLUDED' ? hook.form.handleClose : ()=>{}}
                    >
                        <span className="b3-bold">
                            {props.schedule?.status === 'CONCLUDED' ? 
                                'Voltar' : 
                                hook.form.isLoadingSubmit ? 
                                'Salvando...' : 
                                'Salvar agendamento'
                            }
                        </span>
                    </ButtonComponent>

                </FormComponent>
            </S.FormScheduleDialogContainer>
        </DialogComponent>
    );
};
