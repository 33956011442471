import { z } from 'zod';

export const finalizeSchema = z.object({
    fkScheduleId: z.number().optional(),
    discounts: z.string().optional().transform(value=>(value ? parseInt(value.replace(/[^\d]/g, ''))/100 : 0).toString()),
    addition: z.string().optional().transform(value=>(value ? parseInt(value.replace(/[^\d]/g, ''))/100 : 0).toString()),
    observation: z.string({message : 'O campo "Observação" é obrigatório.'}),
    receive: z.boolean().default(false),
    total: z.string()
    .refine(value => {
        const numericValue = parseInt(value.replace(/(?!^-)[^\d]/g, ''));
        return numericValue > 0;
    }, {
        message: 'Campo "Total" deve ser maior que 0'
    })
    .transform(value => (parseInt(value.replace(/[^\d]/g, '')) / 100).toString()),
});

export type FinalizeSchemaType = z.infer<typeof finalizeSchema>;
