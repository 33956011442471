import * as S from './styled';
import logo_image from '../../../../../assets/images/logo.png';
import { FormComponent } from '../../../../components/form';
import { ButtonComponent } from '../../../../components/button';
import { useLoginHook } from './hook';

export const LoginPage = () => {

    const hook = useLoginHook();

    return (
        <S.LoginContainer>
            <S.LoginContent>
                <img className='logo' src={logo_image} alt='Logo Crefis' />

                <FormComponent onSubmit={hook.handleSubmit(hook.onSubmit)}>
                    <S.LoginFormContainer>
                        <div className='form-group'>
                            <label>Usuário</label>
                            <input type='text' id='login' {...hook.register('login')} />
                            <span className='error'>{hook.errors.login?.message}</span>
                        </div>

                        <div className='form-group'>
                            <label>Senha</label>
                            <input type='password' id='password' {...hook.register('password')} />
                            <span className='error'>{hook.errors.password?.message}</span>
                        </div>
                    </S.LoginFormContainer>

                    <ButtonComponent buttonStyle='primary' type='submit' disabled={hook.isLoading}>
                        <span>{hook.isLoading ? 'Entrando...' : 'Entrar'}</span>
                    </ButtonComponent>
                </FormComponent>
            </S.LoginContent>
        </S.LoginContainer>

    )
}