import styled from "styled-components";

export const FormPatientDialogContainer = styled.div`

`;

export const FormPatientDialogContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;