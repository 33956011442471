import { useEffect, useState } from "react";
import { financialQueries, useFinancialService } from "../../../../services/financial.service";
import { FinancialDataType, FinancialInterface } from "../../../../models/financial.interface";
import { useMutation, useQuery } from "react-query";
import { endOfMonth, startOfMonth } from "date-fns";

export function useFinancialHook() {
    const financialService = useFinancialService();
    const [showFormDialog, setShowFormDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedFinancial, setSelectedFinancial] = useState<FinancialInterface | null>();
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [startDate, setStartDate] = useState(startOfMonth(new Date()).toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(endOfMonth(new Date()).toISOString().split('T')[0]);

    const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
        async () => await financialService.deleteFinancial({ id: selectedFinancial!.id!, onSuccess: onSuccessDelete })
    );

    const {
        data: financialData,
        isLoading: isLoadingFinancialData,
        isRefetching: isRefetchingFinancialData,
        refetch: refetchFinancialData
    } = useQuery<FinancialDataType>(financialQueries.GET_ALL, async () => await financialService.getAllFinancial({ search, startDate, endDate, page }));

    function handleCloseFormModal() {
        setSelectedFinancial(null);
        setShowFormDialog(false);
    }

    function handleOpenFormModal(financial: FinancialInterface) {
        setSelectedFinancial(financial);
        setShowFormDialog(true);
    }

    function handleOpenDeleteModal(financial: FinancialInterface) {
        setSelectedFinancial(financial);
        setShowDeleteDialog(true);
    }

    function handleCloseDeleteModal() {
        setSelectedFinancial(null);
        setShowDeleteDialog(false);
    }

    function onSuccessDelete() {
        handleCloseDeleteModal();
        refetchFinancialData();
    }

    function handleDeleteFinancial() {
        mutateDelete();
    }

    function getBalance() {
        const totalIn = financialData?.totalIn || 0;
        const totalOut = financialData?.totalOut || 0;
        return totalIn - totalOut;
    }

    useEffect(()=>{
        refetchFinancialData();
    }, [page, startDate, endDate]);

    return {
        dialogs: {
            showFormDialog,
            showDeleteDialog,
            setShowDeleteDialog,
            setShowFormDialog,
            handleOpenFormModal,
            handleCloseFormModal,
            handleOpenDeleteModal,
            handleCloseDeleteModal
        },
        financial: {
            financialData,
            selectedFinancial,
            isLoading: isLoadingFinancialData || isRefetchingFinancialData,
            isLoadingDelete,
            page,
            startDate,
            endDate,
            setStartDate,
            setEndDate,
            refetchFinancialData,
            handleDeleteFinancial,
            setSearch,
            setPage,
            getBalance
        }
    }
}