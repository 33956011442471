import { createSlice } from '@reduxjs/toolkit';
import { UserInterface } from '../../models/user.interface';

type initialStatePorps = {
    authUser : UserInterface | null;
}

const initialState : initialStatePorps = {
    authUser : null
}

const userSlice = createSlice({
    name: 'User',
    initialState,
    reducers: {
        setUser(state, action: { payload: UserInterface }) {
            state.authUser = action.payload;
        },
    },
});

export const { setUser } = userSlice.actions;
export const userReducer = userSlice.reducer;
