import styled from "styled-components";

export const TableContainer = styled.table`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const TableContent = styled.div`
    width: calc(100% + 18px);
    border-collapse: collapse;
    max-height: calc(100vh - 285px);
    overflow-y: scroll;

    table{
        width: calc(100% - 10px);
        border-collapse: collapse;

        .actions{
            justify-content: flex-end !important;
            margin-right: 10px;
        }

        .d-flex{
            display: flex;
            gap: 8px;
            align-items: center;

            img{
                cursor: pointer;
            }
        }

        .details-hidden{
            height: 1px !important;
            padding: 0 !important;
        }

        thead{
            position: sticky;
            top: 0;
            background-color: var(--white-color);
            z-index: 2;

            &::before{
                content: "";
                height: 1px;
                width: 100%;
                background-color: var(--blue-color-100);
                position: absolute;
                bottom: 0;
            }
            
            th{
                border-bottom: 1px solid var(--blue-color-60);
            }
        }

        td, th{
            padding: 8px 0;
            text-align: start;
            height: 50px;
            max-width: 300px;
        }

        tr{
            border-bottom: 1px solid var(--card-border-color);
            padding: 8px;
        }
    }

    
`;

export const TablePaginationContianer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    margin-top: 20px;
`;