import { useForm } from "react-hook-form";
import { ScheduleSchemaType, scheduleSchema } from "../../../models/schemas/schedule.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { CardScheduleHookType } from "./type";
import { formatPrice } from "../../../utils/validations";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { scheduleQueries, useScheduleService } from "../../../services/schedule.service";
import { ScheduleInterface, ScheduleStatus } from "../../../models/schedule.interface";
import { queryClient } from "../../../utils/query-client";

export function useCardScheduleHook(props: CardScheduleHookType){

    const [selectCancelDialog, setSelectCancelDialog] = useState<ScheduleInterface | null>(null);
    const [selectReceiveDialog, setSelectReceiveDialog] = useState<ScheduleInterface | null>(null);
    const [selectFinalizeDialog, setSelectFinalizeDialog] = useState<ScheduleInterface | null>(null);
    const [detailsDialog, setDetailsDialog] = useState<ScheduleInterface | null>(null);
    const scheduleService = useScheduleService();
    
    const { mutate, isLoading: isLoadingSubmit } = useMutation(
        async (payload: ScheduleSchemaType) => await scheduleService.editSchedule({ payload: payload as ScheduleInterface, onSuccess })
    );


    const scheduleForm = useForm<ScheduleSchemaType>({
        resolver: zodResolver(scheduleSchema.omit({fkPatientId : true, finalizeObservation: true}))
    });
    
    function handleCancelSchedule() {
        const status = ScheduleStatus.CANCELED as keyof typeof ScheduleStatus;
        mutate({...selectCancelDialog, status} as ScheduleSchemaType);
    }

    function onSuccess() {
        setSelectCancelDialog(null);
        queryClient.refetchQueries(scheduleQueries.GET_ALL_GROUP_DATE);
        queryClient.refetchQueries(scheduleQueries.GET_ALL_BY_PATIENT_ID);
        queryClient.refetchQueries(scheduleQueries.GET_BY_PATIENT_ID);
        queryClient.refetchQueries(scheduleQueries.GET_ALL_DUE_TODAY);
    }

    function onSubmit(data : ScheduleSchemaType){
        const { scheduleDate, hour } = data;
        const formattedDate = new Date(`${scheduleDate}T${hour}:00.000Z`);
        mutate({
            ...data,
            scheduleDate: formattedDate.toISOString()
        } as ScheduleInterface);
    }

    useEffect(()=>{
        scheduleForm.reset({
            ...props.schedule,
            scheduleDate: props.schedule?.scheduleDate ? new Date(props.schedule.scheduleDate).toISOString().slice(0, 10) : '',
            hour: props.schedule?.scheduleDate ? new Date(props.schedule.scheduleDate).toISOString().slice(11, 16) : '',
            price: formatPrice(parseFloat(props.schedule?.price.toString() || '0'))

        } as ScheduleSchemaType)
    }, [props.schedule])

    return {
        form: {
            scheduleForm,
            isLoadingSubmit,
            selectCancelDialog,
            selectReceiveDialog,
            selectFinalizeDialog,
            detailsDialog,
            setDetailsDialog,
            setSelectReceiveDialog,
            setSelectFinalizeDialog,
            setSelectCancelDialog,
            onSubmit,
            handleCancelSchedule,

        }
    }
}
