import styled from "styled-components";

export const UserContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const UserSearchBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const UserTableSection = styled.section`

`;