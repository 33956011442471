import * as S from './styled';
import { DialogComponent } from '../dialog';
import { FormComponent } from '../form';
import { FormReceiveDialogType } from './type';
import { ButtonComponent } from '../button';
import { InputCurrencyComponent } from '../inputCurrency';
import { useFormReceiveDialogHook } from './hook';

export const FormReceiveDialog = (props : FormReceiveDialogType)=>{
    const hook = useFormReceiveDialogHook(props);
    
    return (
        <S.FormReceiveDialogContainer>
            <DialogComponent 
                onClose={props.onClose}
                width={330} 
                title='Receber Pagamento'
                isOpen={props.isOpen}
            >
                <FormComponent onSubmit={hook.paymentForm.handleSubmit(hook.onSubmit)}>
                    <S.FormReceiveDialogContent>
                        <div className='form-group'>
                            <label htmlFor="name" className="c1-regular">Nome completo do paciente:</label>
                            <input type='text' id='name' name='name' value={props.schedule?.patient?.name} disabled/>
                        </div> 

                        <div className='form-group'>
                            <label htmlFor="value" className="c1-regular">Valor:</label>
                            <InputCurrencyComponent id='value' value={parseFloat(props.schedule?.price || '0')} disabled register={hook.paymentForm.register}/>
                        </div>

                        <div className='form-group'>
                            <label htmlFor="addition" className="c1-regular">Acrescimos:</label>
                            <InputCurrencyComponent id='addition' value={0} register={hook.paymentForm.register}/>
                            <span className='error c1-regular'>{hook.paymentForm.formState.errors.addition?.message}</span>
                        </div>

                        <div className='form-group'>
                            <label htmlFor="discounts" className="c1-regular">Descontos:</label>
                            <InputCurrencyComponent id='discounts' value={0} register={hook.paymentForm.register}/>
                            <span className='error c1-regular'>{hook.paymentForm.formState.errors.discounts?.message}</span>
                        </div>

                        <div className='form-group'>
                            <label htmlFor="total" className="c1-regular">Valor à Receber:</label>
                            <InputCurrencyComponent id='total' allowNegativeValue={true} value={hook.total} disabled register={hook.paymentForm.register}/>
                            <span className='error c1-regular'>{hook.paymentForm.formState.errors.total?.message}</span>
                        </div>

                        
                    </S.FormReceiveDialogContent>
                    
                    <ButtonComponent buttonStyle='primary' type='submit' disabled={hook.isLoadingSubmit }>
                        <span className="b3-bold">
                            {hook.isLoadingSubmit ? 'Salvando...' : 'Receber'}
                        </span>
                    </ButtonComponent>

                </FormComponent>
            </DialogComponent>
        </S.FormReceiveDialogContainer>
    );
};