import { LocalToken } from '../utils/local-token';
import axios from 'axios';

export const baseUrl = window.location.hostname === 'localhost'
  ? 'http://localhost:8080'
  : 'https://back.crefis.lufial.com.br';

// export const baseUrl = 'https://back.crefis.lufial.com.br';

const api = axios.create({
  baseURL: `${baseUrl}/api/v1`,
});

api.interceptors.request.use(
  (config) => {
    const token = LocalToken.getStoredToken();
    console.log(token)
    config.headers.Authorization = `Bearer ${token}`;
    
    if (config.method?.toUpperCase() === 'OPTIONS') {
      return Promise.reject('Ignorando solicitação OPTIONS');
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);




export default api;