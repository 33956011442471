import { z } from "zod";

export const scheduleSchema = z.object({
    id: z.number().optional(),
    scheduleDate: z.union([
        z.date(),
        z.string().min(1, {message : 'Campo "Data" é obrigatóro'})
    ]),
    hour: z.union([
        z.date(),
        z.string().min(1, {message : 'Campo "Hora" é obrigatório'}),
    ]),
    serviceDate: z.union([
        z.null(),
        z.date().optional(),
        z.string().optional()
    ]).optional(),
    price: z.string().refine(value => {
        const numericValue = parseInt(value.replace(/[^\d]/g, '').replaceAll('R$ ', ''));
        return numericValue || numericValue === 0;
    }, {
        message: 'Campo "Preço" é obrigatório'
    }).transform(value=>(parseInt(value.replace(/[^\d]/g, ''))/100).toString()),
    observation: z.string().optional(),
    finalizeObservation: z.string().optional(),
    status: z.enum(['SCHEDULED', 'CANCELED', 'CONCLUDED']).default('SCHEDULED').transform(value=>value),
    paymentStatus: z.enum(['PENDING', 'PAID OUT']).default('PENDING').transform(value=>value),
    fkPatientId: z.number({message: 'Selecione um paciente'})
});

export type ScheduleSchemaType = z.infer<typeof scheduleSchema>;