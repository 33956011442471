
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import home_icon from '../../../assets/icons/home.svg';
import home_active_icon from '../../../assets/icons/home_active.svg';
import schedule_icon from '../../../assets/icons/schedule.svg';
import schedule_active_icon from '../../../assets/icons/schedule_active.svg';
import patient_icon from '../../../assets/icons/patient.svg';
import patient_active_icon from '../../../assets/icons/patient_active.svg';
import pending_icon from '../../../assets/icons/pending.svg';
import pending_active_icon from '../../../assets/icons/pending_active.svg';
import financial_icon from '../../../assets/icons/financial.svg';
import financial_active_icon from '../../../assets/icons/financial_active.svg';
import settings_icon from '../../../assets/icons/settings.svg';
import settings_active_icon from '../../../assets/icons/settings_active.svg';
import { useAppDispatch } from '../../../redux/store';
import { setSideBarItem } from '../../../redux/features/general-slice';

export function useSidebarHook(){
    const path = window.location.pathname.split('/')[1];
    const [hoveredItem, setHoveredItem] = useState<number | null>();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const sidebarItems = [
        {
            name: 'Home',
            icon: home_icon,
            activeIcon: home_active_icon,
            link: '',
            isActive: false
        },
        {
            name: 'Agenda',
            icon: schedule_icon,
            activeIcon: schedule_active_icon,
            link: 'agenda',
            isActive: false
        },
        {
            name: 'Pacientes',
            icon: patient_icon,
            activeIcon: patient_active_icon,
            link: 'pacientes',
            isActive: false
        },
        {
            name: 'Pendentes',
            icon: pending_icon,
            activeIcon: pending_active_icon,
            link: 'pendentes',
            isActive: false
        },
        {
            name: 'Financeiro',
            icon: financial_icon,
            activeIcon: financial_active_icon,
            link: 'financeiro',
            isActive: false
        },
        {
            name: 'Usuários',
            icon: settings_icon,
            activeIcon: settings_active_icon,
            link: 'usuarios',
            isActive: false
        }
    ];

    function handleMouseEnter(index: number) {
        setHoveredItem(index);
    }

    function handleMouseLeave() {
        setHoveredItem(null);
    }

    useEffect(()=>{
        dispatch(setSideBarItem(sidebarItems.find(item=>item.link.includes(path))!));
    }, [path])

    return{
        path,
        sidebarItems,
        hoveredItem,
        setIsOpenModal,
        isOpenModal,
        handleMouseEnter,
        handleMouseLeave,
        navigate
    }
}
