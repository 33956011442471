import { useMutation } from "react-query";
import { FormFinancialDialogHookType } from "./type";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FinancialSchemaType, financialSchema } from '../../../../models/schemas/financial.schema';
import { FinancialInterface } from "../../../../models/financial.interface";
import { queryClient } from "../../../../utils/query-client";
import { financialQueries, useFinancialService } from "../../../../services/financial.service";
import { formatPrice } from "../../../../utils/validations";

export function useFormFinancialDialogHook(props : FormFinancialDialogHookType){
    const financialService = useFinancialService();

    const { mutate, isLoading: isLoadingSubmit } = useMutation(
        async (payload: FinancialSchemaType) => 
            props.financial?.id ?
            await financialService.editFinancial({payload, onSuccess}) :
            await financialService.createFinancial({payload, onSuccess})
    );

    const financialForm = useForm<FinancialSchemaType>({
        resolver: zodResolver(financialSchema),
        defaultValues: {
            ...props.financial,
            date: props.financial?.date ? new Date(props.financial.date).toISOString().slice(0, 10) : '',
            hour: props.financial?.date ? new Date(props.financial.date).toISOString().slice(11, 16) : '',
            value: formatPrice(parseFloat(props.financial?.value.toString() || '0'))
        } as FinancialSchemaType
    });

    function onSubmit(data: FinancialSchemaType) {
        const { date, hour } = data;
        const formattedDate = new Date(`${date}T${hour}:00.000Z`);
        mutate({
            ...data,
            date: formattedDate.toISOString()
        } as FinancialInterface);
    }

    function onSuccess(){
        queryClient.refetchQueries(financialQueries.GET_ALL);
        if(!props.financial?.id){
            props.onClose();
        }
    }

    return{
        financialForm,
        isLoadingSubmit,
        onSubmit,
    }
}